import React from "react";
import Select from "react-select";

// Styles personnalisés
const customStyles = {
  control: () => ({
    width: "160px",
    display:"flex",
    backgroundColor: "#fff", 
    fontSize: "14px",
    padding: '0px',
    color: "#003a70", 
    border: "1px solid #64646460", 

  }),
  option: ( state) => ({
   
    backgroundColor: state.isSelected
      ? "#007bff" 
      : state.isFocused
      ? "#e9ecef" 
      : "#fff", 
    color: state.isSelected ? "white" : "#003a70", 
    padding: '5px', 
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, 
  }),
};

const SelectSearch = ({ options, onChange }) => {
  return (
    <>
      <Select
        options={options}
        styles={customStyles}
        onChange={onChange}
        placeholder="Sélectionnez..." // Placeholder
      />
    </>
  );
};

export default SelectSearch;

import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Sidebar from "../../Components/Sidebar/Sidebar";
import "./layout.css";

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
      <Navbar />
      <div className="page">
        <Sidebar closeSidebar={isSidebarOpen} toggleSidebar={handleSidebarToggle} />
        <div className="contenu desktop">
          <Outlet />
        </div>
        <div className="contenu mobile" onClick={handleCloseSidebar}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;

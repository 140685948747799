import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Layout from "./Pages/Layout/Layout";
import Commande from "./Pages/Commande/Commande";
import Payement from "./Pages/Payement/Payement";
import Traitement from "./Pages/Traitement/Traitement";
import Fournisseur from "./Pages/Fournisseur/Fournisseur";
import Palette from "./Pages/Palette/Palette";
import Dechet from "./Pages/Dechets/Dechet";
import Archives from "./Pages/Archives/Archives";
import Stock from "./Pages/Livraison/Stock";
import Avance from "./Pages/Avance/Avance";
import Parametre from "./Pages/Parametre/Parametre";
import Login from "./Pages/login/Login";
import { AuthProvider } from "./Context/AuthContext";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import ProtectLogin from "./Components/ProtectLogin";
import ListeBeneficiere from "./Pages/Archives/ListeBeneficiere";
import Performance from "./Pages/Performance/Performance";
import Soufrage from "./Pages/Soufrage/Soufrage";

function App() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route
              index
              element={
                <ProtectLogin route="">
                  <Login />
                </ProtectLogin>
              }
            />
            <Route path="/" element={<Layout />}>
              <Route
                path="dashboard"
                element={
                  <ProtectedRoutes route="">
                    <Dashboard />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="/commande"
                element={
                  <ProtectedRoutes>
                    <Commande />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="/fournisseur"
                element={
                  <ProtectedRoutes>
                    <Fournisseur />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="/payement"
                element={
                  <ProtectedRoutes>
                    <Payement />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="/avance"
                element={
                  <ProtectedRoutes>
                    <Avance />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="/traitement"
                element={
                  <ProtectedRoutes>
                    <Traitement />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="/palette"
                element={
                  <ProtectedRoutes>
                    <Palette />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="/stock"
                element={
                  <ProtectedRoutes>
                    <Stock />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="/dechets"
                element={
                  <ProtectedRoutes>
                    <Dechet />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="/archives"
                element={
                  <ProtectedRoutes>
                    <Archives />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="/parametre"
                element={
                  <ProtectedRoutes>
                    <Parametre />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="archives/beneficiere"
                element={
                  <ProtectedRoutes>
                    <ListeBeneficiere />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="performance"
                element={
                  <ProtectedRoutes>
                    <Performance />
                  </ProtectedRoutes>
                }
              ></Route>
               <Route
                path="soufrage"
                element={
                  <ProtectedRoutes>
                    <Soufrage />
                  </ProtectedRoutes>
                }
              ></Route>
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import NouvFournisseur from "./NouvFournisseur";
import axios from "axios";
import DeleteFournisseur from "./DeleteFournisseur";
import EditFournisseur from "./EditFournisseur";
import Chargement from "../../Components/Chargement/Chargement";
import search_ico from "../../Assets/Icons/icons8-chercher-50.png";
import moins from "../../Assets/Icons/icons8-moins-24.png";
import plus from "../../Assets/Icons/icons8-plus-24.png";

const Fournisseur = () => {
  useEffect(() => {
    document.title = "Fournisseur";
  }, []);

  const [fournisseur, setFournisseur] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(true);

  // État pour gérer les éléments sélectionnés
  const [selectedIds, setSelectedIds] = useState([]);
  // État pour la sélection totale
  const [allSelected, setAllSelected] = useState(false);
  // Fonction pour sélectionner/désélectionner un élément
  const toggleSelect = (item) => {
    setSelectedIds((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };

  // Fonction pour gérer la sélection/désélection de tous les éléments
  const toggleSelectAll = () => {
    if (allSelected) {
      setSelectedIds([]);
    } else {
      // Select all filtered items instead of all data
      setSelectedIds(filteredFournisseur);
    }
    setAllSelected(!allSelected);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://faly-litchi.mg/api/show_fournisseur"
      );
      setFournisseur(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const rafraichirFournisseurs = () => {
    fetchData();
  };

  const filteredFournisseur = fournisseur.filter((frs) => {
    const matchesSearch = frs.nom_fournisseur
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesSearchid = frs.id.toString().includes(searchTerm);
    return matchesSearch || matchesSearchid;
  });

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <>
      <div className="container">
        <div className="header">
          <div className="cadre-option">
            <h1 className="titre-contenu">Liste des fournisseurs</h1>
            <div
              className={`option-view ${showFilters ? "view-open" : ""}`}
              onClick={toggleFilters}
            >
              <img src={showFilters ? moins : plus} alt="toggle filtre" />
            </div>
          </div>

          {showFilters && (
            <div className={`head-commande  ${showFilters ? "show" : ""}`}>
              <NouvFournisseur onAjout={rafraichirFournisseurs} />
              <div className="menu-filtre-search">
                <input
                  type="text"
                  placeholder="Recherche..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm === "" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      backgroundColor: "#127ee3",
                      padding: "5px",
                       marginTop:"5px"
                    }}
                  >
                    <img
                      style={{ width: "19px" }}
                      src={search_ico}
                      alt="search icon"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="cadre-table-scroll">
          {loading ? (
            <Chargement />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={allSelected}
                      onChange={toggleSelectAll}
                    />
                  </th>
                  <th className="th-id text-center">Id</th>
                  <th>Noms</th>
                  <th>STAT</th>
                  <th>NIF</th>
                  <th>CIF</th>
                  <th>Type</th>
                  <th>Adresse</th>
                  <th className="th-contact">Contact</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredFournisseur.length > 0 ? (
                  filteredFournisseur.map((row) => (
                    <tr key={row.id}>
                      <th>
                        <input
                          type="checkbox"
                          checked={selectedIds.includes(row)}
                          onChange={() => toggleSelect(row)}
                        />
                      </th>
                      <th className="th-id text-center">
                        {row.id < 10 ? `F0${row.id}` : `F${row.id}`}
                      </th>
                      <th>
                       {row.nom_fournisseur}
                      </th>
                      <th>{row.stat}</th>
                      <th>{row.nif}</th>
                      <th>{row.cif}</th>
                      <th>{row.type}</th>
                      <th>{row.adresse}</th>
                      <th>
                        <i>{row.contact}</i>
                      </th>
                      <th>
                        <div className="option">
                          <DeleteFournisseur
                            nom_fournisseur={row.nom_fournisseur}
                            id_fournisseur={row.id}
                          />

                          <EditFournisseur
                            nom_fournisseur={row.nom_fournisseur}
                            adresse={row.adresse}
                            contact={row.contact}
                          />
                        </div>
                      </th>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">Aucun resultat</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Fournisseur;

import axios from "axios";
import React, { useState } from "react";
import supp_ico from "../../Assets/Icons/icons8-supprimer-100 (1).png";

const DeleteFournisseur = ({nom_fournisseur, id_fournisseur}) => {
  const [openWin, setOpenWin] = useState(false);
  const [notif, setNotif] = useState("");
  const [isError, setIsError] = useState(false);
  const [openNotif, setOpenNotif] = useState(false);

  const Alerter = () => {
    setOpenWin(true);
  };

  const Annuler = () => {
    setOpenWin(false);
  };

  const Del = async () => {
    try {
      const response = await axios.delete(
        `https://faly-litchi.mg/api/delete_fournisseur/${id_fournisseur}`
      );

      console.log("Suppression réussie :", response.data);
      setNotif("Commande supprimée avec succès !");
      setIsError(false);

      notifySuccess();
      window.location.reload();
    } catch (error) {
      console.error(
        "Erreur lors de la soumission des données :",
        error.response ? error.response.data : error.message
      );
      setNotif("Erreur lors de la suppression de la commande.");
      setIsError(true);
      notifyError();
    } finally {
      setOpenWin(false);
    }
  };

  const notifySuccess = () => {
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  const notifyError = () => {
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  return (
    <>
      <button className="btn btn-delete" onClick={Alerter}>
        <img src={supp_ico} alt="supp_ico" />
      </button>
      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">Suppression</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <p>
              Cette action permet de supprimer Fournisseur
              <span className="span-delete"> {nom_fournisseur}</span>
              <br /> Êtes-vous vraiment sûr de vouloir continuer ?
            </p>
            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Annuler
              </button>
              <button className="btn btn-confirmer" onClick={Del}>
                Confirmer
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Notification */}
      {openNotif && (
        <div className={`container-notif ${isError ? "error" : "success"}`}>
          {notif}
        </div>
      )}
    </>
  );
};

export default DeleteFournisseur;

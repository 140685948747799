import React from "react";
import print_ico from "../../Assets/Icons/icons8-impression-50.png"

const PrintCommande = ({
  id_commande,
  date_commande,
  fournisseur,
  quantite,
}) => {
  const generatePDF = () => {
    const tableau = document.getElementById("divToPrint");
    const nouvelleFenetre = window.open("", "_blank");
    const css = `
      @media print {
        /* Vos styles ici */
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid #101010;
          padding : 4px;
          text-align: left;
        }
        .table-facture thead {
            background-color: #b25e5e;
            color: white;
        }
        .cadre-facture{
            background-color: #fcfcfcd8;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            display: bloc;
        }
        .head-facture{
            padding: 50px 40px;
            display: flex;
            justify-content: end;
        }
        .cadre-facture .info{
            display: flex;
            justify-content: space-between;
        }
        .info{
            padding: 0px 40px;
        }
        .info div{
            display: flex;
            flex-direction: column;
            width: 250px;
        }

        .table-facture{
            padding: 0px 40px;
            margin-top: 80px;
        }
      }
    `;
    nouvelleFenetre.document.head.innerHTML += `<style>${css}</style>`;
    nouvelleFenetre.document.body.innerHTML = tableau.outerHTML;
    nouvelleFenetre.focus();
    nouvelleFenetre.print();
    nouvelleFenetre.close();
  };
  return (
    <>
      <button className="btn btn-edit" style={{backgroundColor:"#00922c"}} onClick={generatePDF}>
        <img src={print_ico} alt="edit_ico" />
      </button>

      <div id="divToPrint" className="cadre-facture">
        <div className="head-facture">
          <h2>BON DE COMMANDE</h2>
        </div>
        <div className="info">
          <label>Demandeur</label>
          <div>
            <label htmlFor="">Date : {date_commande}</label>
            <label htmlFor="">Fournisseur : {fournisseur}</label>
          </div>
        </div>
        <div className="table-facture">
          <table>
            <thead>
              <tr>
                <th className="th-id">Id</th>
                <th>Quantité (T)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>C00{id_commande}</th>
                <th>{quantite}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PrintCommande;

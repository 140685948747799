import React from "react";
import print_ico from "../../Assets/Icons/icons8-impression-50.png";
import logo from "../../Assets/Images/Logo Faly Hordea Vect.png"
import "./facture.css";

const FactureAll = ({ selectedIds }) => {
  const formatNumber = (number) => {
    return number ? number.toLocaleString(undefined, { maximumFractionDigits: 2 }) : "0";
  };

  
  function isNotNumber(value) {
    return typeof value !== "number" || isNaN(value);
  }


  const generatePDF = () => {
    const invoicesHtml = selectedIds.map((entry) => `
      <div class="facture-container page-break"> <!-- Force page break for each invoice -->
        <div class="header-facture">
          <div>
             <h2>FALY HORDEA SARLU</h2>
             <div class="stat">
                  <p>RN5 AMBODIVOAMPENO AMBALAMANASY</p>
                  <p>TOAMASINA SUBURBAIN MADAGASCAR</p>
                  <p>NIF: 100 222 0201</p>
                  <p>STATISTIQUE : 46309 31 2016 0 00252</p>
                  <p>RCS : 2016 8 00028</p>
                  <p>Numéro REX : MG1002220201</p>
                  <p>Mail : </p>
                  <p>Téléphone : (+261) 34 17 088 85</p>
                  <p>(+261) 32 07 088 81</p>
                  <p> (+261) 33 37 088 81</p>
                  <p>FLoID : 28109</p>
               </div>    
          </div>
          <div class="info">
            <h2>Facture N° ${entry.payement_id < 10 ? `F0${entry.payement_id}` : `F${entry.payement_id}`}</h2>
            <p>Fournisseur: <strong>${entry.fournisseur.nom_fournisseur}</strong></p>
             <p>Adresse: ${entry.fournisseur.adresse}</p>
                  <p>STAT: ${!isNotNumber(entry.fournisseur.adresse) ? entry.fournisseur.adresse : ""}</p>
                  <p>NIF: ${!isNotNumber(entry.fournisseur.nif) ? entry.fournisseur.nif : ""}</p>
          </div>
        </div>
        <p>Date de Paiement: ${entry.date_payement}</p>
        <table class="table-facture">
          <thead>
            <tr>
              <th>Référence</th>
              <th class="text-end">Quantité (kg)</th>
              <th class="text-end">PU (Ar)</th>
              <th class="text-end">Total (Ar)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>C0${entry.payement_id}</td>
              <td>${formatNumber(entry.livraison_quantite)} kg</td>
              <td>${formatNumber(entry.prix_jour)} Ar</td>
              <td>${formatNumber(entry.montant_paye + entry.montant_deduise)} Ar</td>
            </tr>
            <tr>
              <td colspan="3"><strong>Avance</strong></td>
              <td>${formatNumber(entry.montant_deduise)} Ar</td>
            </tr>
            <tr>
              <td colspan="3"><strong>Montant</strong></td>
              <td>${formatNumber(entry.montant_paye)} Ar</td>
            </tr>
            <tr>
              <td colspan="3"><strong>Montant Total</strong></td>
              <td><strong>${formatNumber(entry.montant_paye + entry.montant_deduise)} Ar</strong></td>
            </tr>
          </tbody>
        </table>
          <div class="logo"><img src="${logo}" alt="Logo"></div>
        <div class="footer">Merci pour votre confiance.</div>
      </div>
    `).join('');

    const fullHtml = `
      <html>
        <head>
          <title>Factures</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 10px; }
            .stat p { margin:5px }
            .facture-container { width: 100%; margin: 0 auto; }
            .header-facture { display: flex; justify-content: space-between; border-bottom: 1px dashed #ccc; padding-bottom: 10px; }
            .header-facture info {  width: 10px }
            .table-facture { width: 100%; border-collapse: collapse; margin-top: 40px; }
            .table-facture th, .table-facture td { border: 1px solid #ddd; padding: 8px; text-align: right; }
            .table-facture th { background-color: #646464; color: white; }
            .footer { margin-top: 40px; font-size: 12px; text-align: center; color: #777; }
              .logo { display: flex; justify-content: start; text-align: center; margin-top: 20px; }
              .logo img { max-width: 150px; height: auto; } /* Ajuster la taille selon vos besoins */
            .page-break { page-break-after: always; } 
          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${invoicesHtml}
        </body>
      </html>
    `;

    const popupWin = window.open("", "_blank", "width=800,height=600");
    popupWin.document.write(fullHtml);
    popupWin.document.close();
  };

  return (
    <>
      <button className="btn btn-print" onClick={generatePDF}>
        <img src={print_ico} alt="print_icon" /> <label>Imprimer tous</label>
      </button>
    </>
  );
};

export default FactureAll;

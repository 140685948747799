import React, { useEffect, useState } from "react";
import "./payement.css";
import axios from "axios";
import Chargement from "../../Components/Chargement/Chargement";
import moins from "../../Assets/Icons/icons8-moins-24.png";
import plus from "../../Assets/Icons/icons8-plus-24.png";
import search_ico from "../../Assets/Icons/icons8-chercher-50.png";
import Facture from "./Facture";
import FactureAll from "./FactureAll";

const Payement = () => {
  useEffect(() => {
    document.title = "Payement";
  }, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilters, setShowFilters] = useState(true);
  const [filterDate, setFilterDate] = useState("");

  // État pour gérer les éléments sélectionnés
  const [selectedIds, setSelectedIds] = useState([]);

  // État pour la sélection totale
  const [allSelected, setAllSelected] = useState(false);

  // Fonction pour sélectionner/désélectionner un élément
  const toggleSelect = (item) => {
    setSelectedIds((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };

  // Fonction pour gérer la sélection/désélection de tous les éléments
  const toggleSelectAll = () => {
    if (allSelected) {
      setSelectedIds([]);
    } else {
      // Select all filtered items instead of all data
      setSelectedIds(filteredPayement);
    }
    setAllSelected(!allSelected);
  };

  // GET payement
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://faly-litchi.mg/api/show_payement"
        );
        setData(response.data);
        console.log("données :", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Formatage montant
  const formatNumber = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  // Filtre d'affichage
  const filteredPayement = data.filter((payement) => {
    const matchesSearch = payement.fournisseur.nom_fournisseur
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesSearchid = payement.livraison_id
      .toString()
      .includes(searchTerm);
    const matchesFilterDate =
      filterDate === "" || payement.date_payement === filterDate;

    return (matchesSearch || matchesSearchid) && matchesFilterDate;
  });

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  // Check if any of the filtered payments are selected
  const areAnyFilteredSelected = filteredPayement.some((row) =>
    selectedIds.includes(row)
  );

  return (
    <>
      <div className="container">
        <div className="header">
          <div className="cadre-option">
            <h1 className="titre-contenu">Liste des payements</h1>
            <div
              className={`option-view ${showFilters ? "view-open" : ""}`}
              onClick={toggleFilters}
            >
              <img src={showFilters ? moins : plus} alt="toggle filtre" />
            </div>
          </div>
          {showFilters && (
            <div className={`head-commande  ${showFilters ? "show" : ""}`}>
              <div className="menu-filtre-search">
                <input
                  type="text"
                  placeholder="Recherche..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm === "" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      backgroundColor: "#127ee3",
                      padding: "5px",
                      marginTop:"5px"
                    }}
                  >
                    <img
                      style={{ width: "19px" }}
                      src={search_ico}
                      alt="search icon"
                    />
                  </div>
                )}
              </div>
              <input
                className="menu-filtre"
                type="date"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)} // Update filterDate state
              />
              <div
                className=""
                style={{
                  pointerEvents: areAnyFilteredSelected ? "auto" : "none",
                }}
              >
                <FactureAll
                  selectedIds={selectedIds.filter((id) =>
                    filteredPayement.includes(id)
                  )}
                />
              </div>
            </div>
          )}
        </div>

        <div className="cadre-table-scroll">
          {loading ? (
            <Chargement />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={allSelected}
                      onChange={toggleSelectAll}
                    />
                  </th>
                  <th className="">Id Livraison</th>
                  <th className="text-center">Date</th>
                  <th className="">Fournisseur</th>
                  <th className="text-end">Quantité</th>
                  <th className="text-end">% déchets</th>
                  <th className="text-end">PU (kg)</th>
                  <th className="text-end">Montant</th>
                  <th className="text-end">Avance</th>
                  <th className="text-center">Mode de payement</th>
                  <th className="th-id">Options</th>
                </tr>
              </thead>
              <tbody>
                {filteredPayement.length > 0 ? (
                  filteredPayement.map((row) => (
                    <tr key={row.payement_id}>
                      <th>
                        <input
                          type="checkbox"
                          checked={selectedIds.includes(row)}
                          onChange={() => toggleSelect(row)}
                        />
                      </th>
                      <th className="text-center">
                        {row.livraison_id < 10
                          ? `L0${row.livraison_id}`
                          : `L${row.livraison_id}`}
                      </th>
                      <th className="text-center">{row.date_payement} </th>
                      <th>
                        {row.sous_fournisseur && row.sous_fournisseur !== "Null"
                          ? row.sous_fournisseur.nom_sous_fournisseur +
                            " (" +
                            row.fournisseur.nom_fournisseur +
                            ")"
                          : row.fournisseur.nom_fournisseur}
                      </th>
                      {/* <th>{row.sous_fournisseur.nom_sous_fournisseur}</th> */}

                      <th className="text-end">
                        <i>{formatNumber(row.livraison_quantite)} Kg </i>
                      </th>
                      <th className="text-end"></th>
                      <th className="text-end">
                        <span className="color-money">
                          <i>{formatNumber(row.prix_jour)} AR </i>
                        </span>
                      </th>
                      <th className="text-end">
                        <span className="color-money">
                          <i>{formatNumber(row.montant_paye)} AR </i>
                        </span>
                      </th>
                      <th className="text-end">
                        <span className="color-money">
                          <i>{formatNumber(row.montant_deduise)} AR </i>
                        </span>
                      </th>

                      <th className="text-center">
                        <i>{row.mode_payement}</i>
                      </th>
                      <th className="text-center">
                        <Facture
                          payement_id={row.payement_id}
                          date_peyement={row.date_payement}
                          nom_fournisseur={
                            row.sous_fournisseur &&
                            row.sous_fournisseur !== "Null"
                              ? row.sous_fournisseur.nom_sous_fournisseur
                              : row.fournisseur.nom_fournisseur
                          }
                          montant_paye={row.montant_paye}
                          adressefrs={row.fournisseur.adresse}
                          statfrs={row.fournisseur.stat}
                          niffrs={row.fournisseur.nif}
                          contact={row.fournisseur.contact}
                          montant_deduise={row.montant_deduise}
                          mode_payement={row.mode_payement}
                          quantite={row.livraison_quantite}
                          pu={row.prix_jour}
                        />
                      </th>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">Aucun resultat</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Payement;

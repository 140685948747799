import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";

function ProtectedRoutes({ children }) {
  const { username } = useAuth();
  const storedLoggedIn = localStorage.getItem("isLoggedIn");

  if (storedLoggedIn === "false" ) {
    if (!username) {
      return <Navigate to={"/"} />;
    }
  }

  return children;
}

export default ProtectedRoutes;

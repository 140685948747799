import React, { useState } from "react";
import print_ico from "../../Assets/Icons/icons8-impression-50.png";
import logo from "../../Assets/Images/Logo Faly Hordea Vect.png"
import "./facture.css";

const Facture = ({
  payement_id,
  date_peyement,
  nom_fournisseur,
  montant_paye,
  montant_deduise,
  mode_payement,
  contact,
  quantite,
  niffrs,
  statfrs,
  adressefrs,
  pu,
}) => {
  const [openWin, setOpenWin] = useState(false);

  const Alerter = () => {
    setOpenWin(true);
  };
  function isNotNumber(value) {
    return typeof value !== "number" || isNaN(value);
  }


  const Annuler = () => {
    setOpenWin(false);
  };

  const formatNumber = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };


  const generatePDF = () => {
      const popupWin = window.open("", "_blank", "width=800,height=600");
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Facture</title>
            <style>
              body { font-family: Arial, sans-serif; padding: 10px; }
              .stat p { margin: 5px; }
              .facture-container { width: 100%; margin: 0 auto; }
              .header-facture { display: flex; justify-content: space-between; border-bottom: 1px dashed #ccc; padding-bottom: 10px; }
              .table-facture { width: 100%; border-collapse: collapse; margin-top: 40px; }
              .table-facture th, .table-facture td { border: 1px solid #ddd; padding: 8px; text-align: right; }
              .table-facture th { background-color: #646464; color: white; }
              .logo { display: flex; justify-content: start; text-align: center; margin-top: 20px; }
              .logo img { max-width: 150px; height: auto; } /* Ajuster la taille selon vos besoins */
              .footer { margin-top: 40px; font-size: 12px; text-align: center; color: #777; }
              .page-break { page-break-after: always; }
            </style>
          </head>
          <body onload="window.print(); window.close();">
            <div class="facture-container">
              <div class="header-facture">
                <div>
                  <h2>FALY HORDEA SARLU</h2>
                  <div class="stat">
                    <p>RN5 AMBODIVOAMPENO AMBALAMANASY</p>
                    <p>TOAMASINA SUBURBAIN MADAGASCAR</p>
                    <p>NIF: 100 222 0201</p>
                    <p>STATISTIQUE : 46309 31 2016 0 00252</p>
                    <p>RCS : 2016 8 00028</p>
                    <p>Numéro REX : MG1002220201</p>
                    <p>Mail : </p>
                    <p>Téléphone : (+261) 34 17 088 85</p>
                    <p>(+261) 32 07 088 81</p>
                    <p>(+261) 33 37 088 81</p>
                    <p>FLoID : 28109</p>
                  </div>  
                </div>
                <div>
                  <h2>Facture N° ${
                    payement_id < 10 ? `F0${payement_id}` : `F${payement_id}`
                  }</h2>
                  <p>Fournisseur: <strong>${nom_fournisseur}</strong></p>
                  <p>Adresse: ${!isNotNumber(adressefrs) ? adressefrs : ""}</p>
                  <p>STAT: ${!isNotNumber(statfrs) ? statfrs : ""}</p>
                  <p>NIF: ${!isNotNumber(niffrs) ? niffrs : ""}</p>
                </div>
              </div>
              <p>Date de Paiement: ${date_peyement}</p>
              <table class="table-facture">
                <thead>
                  <tr>
                    <th>Référence</th>
                    <th>Quantité (kg)</th>
                    <th>PU (Ar)</th>
                    <th>Total (Ar)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>C0${payement_id}</td>
                    <td>${formatNumber(quantite)} kg</td>
                    <td>${formatNumber(pu)} Ar</td>
                    <td>${formatNumber(montant_paye + montant_deduise)} Ar</td>
                  </tr>
                  <tr>
                    <td colspan="3"><strong>Avance</strong></td>
                    <td>${formatNumber(montant_deduise)} Ar</td>
                  </tr>
                  <tr>
                    <td colspan="3"><strong>Montant</strong></td>
                    <td>${formatNumber(montant_paye)} Ar</td>
                  </tr>
                  <tr>
                    <td colspan="3"><strong>Montant Total</strong></td>
                    <td><strong>${formatNumber(montant_paye + montant_deduise)} Ar</strong></td>
                  </tr>
                </tbody>
              </table>
              <div class="logo"><img src="${logo}" alt="Logo"></div>
              <div class="footer">Merci pour votre confiance.</div>
            </div>
          </body>
        </html>
      `);
      popupWin.document.close();
  };
  

  return (
    <>
      <button className="btn btn-print" onClick={Alerter}>
        <img src={print_ico} alt="print_icon" /> <label>Facture</label>
      </button>

      {openWin && (
        <div className="cadre-popup">
          <div className="popup-facture">
            <div className="bar-popup">
              <div className="titre-popup">FACTURE</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <div id="divToPrint" className="facture-container">
              <div className="header-facture">
                <div>
                  <h2>FALY HORDEA SARLU</h2>
                  <p>RN5 AMBODIVOAMPENO AMBALAMANASY</p>
                  <p>TOAMASINA SUBURBAIN MADAGASCAR</p>
                  <p>NIF: 100 222 0201</p>
                  <p>STATISTIQUE : 46309 31 2016 0 00252</p>
                  <p>RCS : 2016 8 00028</p>
                  <p>Numéro REX : MG1002220201</p>
                  <p>Mail : </p>
                  <p>Téléphone : (+261) 34 17 088 85</p>
                  <p>32 07 088 81</p>
                  <p>33 37 088 81</p>
                  <p>FLoID : 28109</p>
                </div>
                <div>
                  <h2>Facture N° F0{payement_id}</h2>
                  <p>
                    Fournisseur: <strong>{nom_fournisseur}</strong>
                  </p>
                  <p>Adresse : {adressefrs}</p>
                  <p>STAT: {statfrs}</p>
                  <p>NIF: {niffrs}</p>
                  <p>Contact: {contact}</p>
                </div>
              </div>
              <div className="facture-details">
                <p>Date : {date_peyement}</p>
              </div>
              <div className="table-facture">
                <table>
                  <thead>
                    <tr>
                      <th>Référence</th>
                      <th>Quantité (kg)</th>
                      <th>PU (Ar)</th>
                      <th>Total (AR)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>C0{payement_id}</td>
                      <td>{formatNumber(quantite)} kg</td>
                      <td>{formatNumber(pu)} Ar</td>
                      <td>{formatNumber(montant_paye + montant_deduise)} AR</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <strong>Avance</strong>
                      </td>
                      <td>{formatNumber(montant_deduise)} AR</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <strong>Montant</strong>
                      </td>
                      <td>{formatNumber(montant_paye)} AR</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <strong>Montant total à payer</strong>
                      </td>
                      <td>
                        <strong>
                          {formatNumber(montant_paye + montant_deduise)} AR
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="footer">Merci pour votre confiance.</div>
              </div>
            </div>
            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Annuler
              </button>
              <button className="btn btn-confirmer" onClick={generatePDF}>
                imprimer
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Facture;

import React, { useEffect, useState } from "react";
import axios from "axios";
import SelectSearch from "../../Components/SelectSearch/SelectSearch";

const NouvCommande = ({ onAjout }) => {
  // Les données
  const [commandeNo, setCommandeNo] = useState(1);
  const [quantite, setQuantite] = useState(0);
  const etat = "Envoyé";
  const [avance, setAvance] = useState(0);
  const [date_commande, setDate_commande] = useState(
    new Date().toISOString().slice(0, 10)
  );

  // FOURNISSEUR
  const [fournisseurs, setFournisseurs] = useState([]);
  const [fournisseurId, setFournisseurId] = useState("");

  // AUTRES
  const [openWin, setOpenWin] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // NOTIFICATIONS
  const [notif, setNotif] = useState("");
  const [openNotif, setOpenNotif] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  function isNotNumber(value) {
    return typeof value !== "number" || isNaN(value);
  }

  // Fonction pour formater le montant avec des séparateurs de milliers
  const formatMontant = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  // POSTER UNE COMMANDE
  const handleSubmit = async () => {
    setIsSubmitting(true);
    // Validation
    if (!fournisseurId) {
      notifyError("Veuillez selectionner un fournisseur");
      setIsSubmitting(false);
      return;
    }
    if (quantite <= 0) {
      notifyError("La quantité ne peut pas être 0");
      setIsSubmitting(false);
      return;
    }

    if (isChecked && avance <= 0) {
      notifyError("Veuillez saisir l'avance");
      setIsSubmitting(false);
      return;
    }

    const formData = {
      date: date_commande,
      fournisseur_id: fournisseurId,
      quantite: quantite,
      etat: etat,
      montant_avance: isChecked ? avance : 0,
    };
    console.log("Les données :", formData);
    try {
      const response = await axios.post(
        "https://faly-litchi.mg/api/create_commande",
        formData
      );
      notifySuccess();
      console.log("Ajout réussi :", response.data);
      setOpenWin(false);
      setIsSubmitting(false);
      Initialise();
      onAjout();
    } catch (error) {
      notifyError("Erreur lors de l'enregistrement de la commande.");
      console.error(
        "Erreur lors de la soumission des données :",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // OUVERTURE POPUP
  const Alerter = () => {
    setOpenWin(true);
  };

  // FERMER POPUP
  const Annuler = () => {
    setOpenWin(false);
    Initialise();
  };

  // Initialiser les valeurs
  const Initialise = () => {
    setDate_commande(new Date().toISOString().slice(0, 10));
    setFournisseurId("");
    setQuantite(0);
    fetchLastIdCommande();
    setIsSubmitting(false);
    setIsChecked(false);
    setAvance(0);
  };

  // AFFICHE NOTIFICATIONS SUCCESS
  const notifySuccess = () => {
    setNotif("Opération réussie !");
    setIsError(false);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
    Initialise();
  };

  // AFFICHE NOTIFICATIONS ERROR
  const notifyError = (message) => {
    setNotif(message);
    setIsError(true);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  // GET ALL FOURNISSEURS
  useEffect(() => {
    const fetchFournisseurs = async () => {
      try {
        const url = "https://faly-litchi.mg/api/show_fournisseur";
        const response = await axios.get(url);
        const options = response.data.map((fournisseur) => ({
          value: fournisseur.id,
          label: fournisseur.nom_fournisseur,
        }));
        setFournisseurs(options);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des fournisseurs :",
          error
        );
      }
    };

    fetchFournisseurs();
  }, []);

  // GET LAST ID COMMANDE
  const fetchLastIdCommande = async () => {
    try {
      const url = "https://faly-litchi.mg/api/Show_lastIdCommande";
      const response = await axios.get(url);
      setCommandeNo(response.data.id + 1);
    } catch (error) {
      console.error("Erreur lors de la récupération de l'ID :", error);
    }
  };

  useEffect(() => {
    fetchLastIdCommande();
  }, []);

  // GET DETAILS AVANCE D'UN FOURNISSEUR
  useEffect(() => {
    if (fournisseurId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://faly-litchi.mg/api/details_avance/${fournisseurId}`
          );
          setIsChecked(!!response.data.montant_avance); // Coche la case si une avance existe
          if (response.data.montant_avance) {
            setAvance(response.data.montant_avance); // Affiche l'avance dans l'input
          } else {
            setAvance(0); // Réinitialise si pas d'avance
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des détails de l'avance :",
            error
          );
        }
      };

      fetchData();
    } else {
      setIsChecked(false);
      setAvance(0); // Réinitialise si aucun fournisseur sélectionné
    }
  }, [fournisseurId]);

  // FORMATAGE MONTANT AVANCE
  const formatNumber = (value) => {
    const numericValue = value.replace(/[^\d]/g, "");
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  // input avance
  const handleChangeAvance = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setAvance(formattedValue);
  };

  // input quantité
  const handleQuantiteChange = (event) => {
    const inputValue = event.target.value.slice(0, 5); // Limit to 5 characters
    const formattedValue = parseFloat(inputValue) || 0;
    setQuantite(formattedValue);
  };

  // SELECT + SEARCH
  const handleSelectChange = (option) => {
    setFournisseurId(option.value);
  };

  return (
    <>
      <button className="btn-commande menu-filtre" onClick={Alerter}>
        <div> Nouvelle Commande</div>
      </button>
      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">NOUVELLE COMMANDE</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form className="form-popup">
              <label htmlFor="">Commande N° :</label>
              <input
                type="text"
                value={
                  commandeNo < 10
                    ? `C0${!isNotNumber(commandeNo) ? commandeNo : 1}`
                    : `C${!isNotNumber(commandeNo) ? commandeNo : 1}`
                }
                disabled
              />
              <label htmlFor="">Date :</label>
              <input
                type="date"
                value={date_commande}
                onChange={(e) => setDate_commande(e.target.value)}
              />
              <label htmlFor="">Fournisseur :</label>
              <SelectSearch
                options={fournisseurs}
                onChange={handleSelectChange}
              />
              <label htmlFor="">Quantité (en kg) :</label>
              <input
                type="text"
                placeholder="En T"
                maxLength={5}
                value={quantite}
                onChange={handleQuantiteChange}
              />
              <br />
              <div className="autre-input">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label>Avec avance </label>
              </div>
              {isChecked && (
                <input
                  type="text"
                  placeholder="en AR"
                  value={formatMontant(avance)}
                  onChange={handleChangeAvance}
                />
              )}
            </form>
            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Annuler
              </button>
              <button
                className="btn btn-confirmer"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Envoi..." : "Enregistrer"}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Notification */}
      <div className={`container-notif ${openNotif ? "show" : "hide"}`}>
        {openNotif && (
          <div className={isError ? "error" : "success"}>{notif}</div>
        )}
      </div>
    </>
  );
};

export default NouvCommande;

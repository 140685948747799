import React, { useState } from "react";

const DetailPalette = ({
  numPalette,
  type,
  nombreCarton,
  nombreFournisseur,
  fournisseurs,
}) => {
  // State to manage popup visibility
  const [openWin, setOpenWin] = useState(false);

  // Open popup
  const Alerter = () => {
    setOpenWin(true);
  };

  // Close popup
  const Annuler = () => {
    setOpenWin(false);
  };

  const formatNumber = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };


  return (
    <>
      <button className="btn btn-print" onClick={Alerter}>
        <label>Details</label>
      </button>

      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">Détails de la palette</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>

            <div className="detail-palette">
              <table>
                <tbody>
                  <tr>
                    <td>Numéro de Palette</td>
                    <td className="text-end">{numPalette}</td>
                  </tr>
                  <tr>
                    <td>Type de Palette </td>
                    <td className="text-end">{type}</td>
                  </tr>

                  <tr>
                    <td>Nombre de Fournisseurs</td>
                    <td className="text-end">{nombreFournisseur}</td>
                  </tr>
                  <tr>
                    <td>Total quatité</td>
                    <td className="text-end">{formatNumber(type*nombreCarton)} kg</td>
                  </tr>
                  <tr className="titre-table">
                    <th>Nom fournisseur</th>
                    <th> Cartons</th>
                  </tr>
                  {fournisseurs.map((fournisseur, index) => (
                    <tr key={index}>
                      <td>{fournisseur.nom}</td>
                      <td className="text-end"> {fournisseur.nombreCarton}</td>
                    </tr>
                  ))}
                  <tr  style={{ backgroundColor: "#b90a28", color: "#d9d9d9" }}>
                    <td
                     
                    >
                      <strong> Total Cartons </strong>
                    </td>
                    <td className="text-end">
                      <strong>{nombreCarton}</strong>
                    </td>
                  </tr>
                </tbody>
                <thead></thead>
              </table>
            </div>
            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailPalette;

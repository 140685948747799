import React, { useEffect, useState } from "react";
import axios from "axios";

const PayeLivraison = ({
  fournisseur_id,
  commande_id,
  livraison_id,
  date_livraison,
  sous_fournisseur,
  nom_fournisseur,
  nombre_caissette,
  sous_fournisseur_id,
  onAjout,
}) => {
  const [openWin, setOpenWin] = useState(false);
  const [openWinDeduction, setOpenWinDeduction] = useState(false);
  const [date_payement, setDate_payement] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [montant, setMontant] = useState(0);
  const [todayPrice, setTodayPrice] = useState(null);
  const commandeNo = commande_id;
  const dateL = date_livraison;
  const [mode_payement, setMode_payement] = useState("");
  const quantite_livre = nombre_caissette;
  const fournisseur = nom_fournisseur;
  const [montant_avance, setMontant_avance] = useState(0);
  const [prcDechet, setPrcDechet] = useState(null);
  const [deduiAvance, setDeduiAvance] = useState(0);
  const [mtpaye, setMtpaye] = useState(null);

  // NOTIFICATIONS
  const [notif, setNotif] = useState("");
  const [openNotif, setOpenNotif] = useState(false);
  const [isError, setIsError] = useState(false);
  const isSuccess = false;
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Calcul du montant à payer
  useEffect(() => {
    const qtdechet = (prcDechet * quantite_livre) / 100;
    const montantCalculé = (quantite_livre - qtdechet) * todayPrice;
    setMontant(Math.floor(montantCalculé)); // Arrondi à l'entier inférieur
  }, [prcDechet, quantite_livre, todayPrice]);

  // GET COMMANDE DETAILS
  useEffect(() => {
    if (commandeNo) {
      const fetchCommandeDetails = async () => {
        try {
          const url = `https://faly-litchi.mg/api/details_commande/${commandeNo}`;
          const response = await axios.get(url);
          setMontant_avance(response.data?.montant_avance || 0);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des commandes non livrées:",
            error
          );
        }
      };

      fetchCommandeDetails();
    }
  }, [commandeNo]);

  // Fonction pour formater le montant avec des séparateurs de milliers
  const formatMontant = (value) => {
    if (value == null) return "0"; // or return an empty string if you prefer
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const Alerter = () => {
    console.log(sous_fournisseur_id);
    setOpenWin(true);
  };

  // INITIALISE LES VALEURS
  const Initialise = () => {
    setPrcDechet();
    setDeduiAvance();
  };

  const notifySuccess = () => {
    setNotif("Opération réussie !");
    setIsError(false);
    setOpenNotif(true);
    setOpenWinDeduction(false);
    // window.location.reload();
    Initialise();
  };

  const notifyError = (message) => {
    setNotif(message);
    setIsError(true);
    setOpenNotif(true);
    // setTimeout(() => setOpenNotif(false), 5000);
  };

  const SavePaye = async () => {
    if (
      deduiAvance > montant_avance ||
      mode_payement === "" ||
      deduiAvance > montant
    ) {
      notifyError("L'avance est inférieure au montant que vous avez entré.");
    } else {
      setIsSubmitting(true);
      notifyError("");
      setMtpaye(montant - deduiAvance);
      setMontant_avance(montant_avance - deduiAvance);
      setDeduiAvance(0);

      const formData = {
        livraison_id,
        fournisseur_id,
        prix_jour: todayPrice,
        montant_paye: montant - deduiAvance,
        avance_deduise: deduiAvance,
        date_payement,
        mode_payement,
        sous_fournisseur_id,
        pourcentage_dechet: parseInt(prcDechet),
      };
      console.log(formData);

      try {
        const response = await axios.post(
          "https://faly-litchi.mg/api/create_payement",
          formData
        );
        notifySuccess();
        console.log("Ajout réussi :", response.data);
        setOpenWin(false);
        setIsSubmitting(false);
        Initialise();
        window.location.reload();
        onAjout();
      } catch (error) {
        notifyError("Erreur lors de l'enregistrement de la commande.", error);
        console.log(error);
      } finally {
        setIsSubmitting(false);
      }
    }
    setOpenWin(false);
  };

  const AlerteAvance = async () => {
    if (prcDechet === null) {
      // notifyError("Veuillez saisissez le % déchets.");
      notifyError("Veuillez completer le pourcentage de déchets.");
      return;
    }
    if (todayPrice === null) {
      notifyError("Veuillez completer le prix du litchi par kg.");
      return;
    }
    if (mode_payement === "") {
      notifyError("Pas de mode de paiement sélectionné.");
      return;
    }

    if (montant_avance > 0 || montant_avance !== 0) {
      notifyError("");
      setOpenWin(false);
      setOpenWinDeduction(true);
    } else {
      setIsSubmitting(true);
      const formData = {
        livraison_id,
        fournisseur_id,
        prix_jour: todayPrice,
        montant_paye: montant,
        avance_deduise: 0,
        date_payement,
        sous_fournisseur_id,
        mode_payement,
        pourcentage_dechet: parseInt(prcDechet),
      };
      console.log("sous :", formData);
      try {
        const response = await axios.post(
          "https://faly-litchi.mg/api/create_payement",
          formData
        );
        notifySuccess();
        console.log("Ajout réussi :", response.data);
        setOpenWin(false);
        setIsSubmitting(false);
        Initialise();

        onAjout();
      } catch (error) {
        // notifyError("Erreur lors de l'enregistrement de la payement.", error);
        console.log(error);
        window.location.reload();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const Annuler = () => {
    setOpenWin(false);
    Initialise();
    setOpenWinDeduction(false);
  };

  const handleChangeDeduiAvance = (event) => {
    const inputValue = event.target.value;
    const numericValue = parseFloat(inputValue.replace(/[^\d.-]/g, "")) || 0;
    setDeduiAvance(numericValue);
  };

  return (
    <>
      <button
        className="btn btn-payer"
        style={{ width: "100%" }}
        onClick={Alerter}
      >
        Payer
      </button>
      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">PAYEMENT</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form className="form-popup">
              <label htmlFor="date_payement">Date de paiement :</label>
              <input
                type="date"
                id="date_payement"
                value={date_payement}
                onChange={(e) => setDate_payement(e.target.value)}
              />

              <label htmlFor="date_livraison">Date de livraison :</label>
              <input type="date" id="date_livraison" value={dateL} disabled />

              <label htmlFor="fournisseur">Fournisseur :</label>
              <input
                type="text"
                value={
                  sous_fournisseur !== null ? sous_fournisseur : fournisseur
                }
                disabled
              />

              <div className="input-quantite">
                <div>
                  <label>Quantité (kg) :</label>
                  <input
                    type="text"
                    value={formatMontant(quantite_livre)}
                    disabled
                  />
                </div>
                <div>
                  <label>Déchets % :</label>
                  <input
                    type="number"
                    value={prcDechet}
                    maxLength={2}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!value.startsWith("-")) {
                        setPrcDechet(value.slice(0, 2));
                      }
                    }}
                    placeholder="Qté des déchets..."
                  />
                </div>
              </div>

              <div className="input-quantite">
                <div>
                  <label>Prix par kg :</label>
                  <input
                    type="text"
                    maxLength={4}
                    value={todayPrice || ""}
                    onChange={(e) =>
                      setTodayPrice(parseFloat(e.target.value.slice(0, 4)))
                    }
                  />
                </div>
                <div>
                  <label>Montant à payer (Ar) :</label>
                  <input
                    type="text"
                    value={`${formatMontant(montant)} AR`}
                    disabled
                  />
                </div>
              </div>

              <div>
                <label htmlFor="mode_payement">Mode de paiement :</label>
                <select
                  onChange={(e) => setMode_payement(e.target.value)}
                  value={mode_payement}
                >
                  <option value="Espèces">Espèces</option>
                  <option value="Chèques">Chèques</option>
                  <option value="Mvola">Mvola</option>
                  <option value="Virement">Virement</option>
                  <option value="AirtelMoney">AirtelMoney</option>
                  <option value="Espace payeur">Espace payeur</option>
                </select>
              </div>
            </form>

            <div className="alert-action">
              {openNotif && (
                <div className={`${isError ? "red-error" : "success"}`}>
                  <span>{notif}</span>
                </div>
              )}

              {isSuccess ? (
                <div className="success">
                  <span>
                    Payement enregistré et validé{" "}
                    <strong>{formatMontant(mtpaye)} Ar</strong>
                  </span>
                </div>
              ) : (
                <>
                  {" "}
                  <button className="btn btn-annuler" onClick={Annuler}>
                    Annuler
                  </button>
                  <button
                    className="btn btn-confirmer"
                    onClick={AlerteAvance}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Envoi..." : "Enregistrer"}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {openWinDeduction && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">DÉDUCTION AVANCE</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form className="form-popup">
              <label htmlFor="prix">Prix (Ar):</label>
              <input type="text" value={formatMontant(montant)} disabled />
              <label htmlFor="montant_avance">Avance restante (Ar):</label>
              <input
                type="text"
                value={formatMontant(montant_avance)}
                disabled
              />
              <label htmlFor="deduiAvance">Montant à déduire (Ar):</label>
              <input
                type="text"
                value={formatMontant(deduiAvance)}
                onChange={handleChangeDeduiAvance}
                placeholder="Montant de l'avance..."
              />
              {openNotif && (
                <div className={`${isError ? "red-error" : "success"}`}>
                  <span>{notif}</span>
                </div>
              )}
            </form>
            <div className="alert-action">
              {isSuccess ? (
                <div className="success">
                  <span>
                    Payement enregistré et validé{" "}
                    <strong>{formatMontant(mtpaye)} Ar</strong>
                  </span>
                </div>
              ) : (
                <>
                  <button className="btn btn-annuler" onClick={Annuler}>
                    Annuler
                  </button>
                  <button
                    className="btn btn-confirmer"
                    onClick={SavePaye}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Envoi..." : "Enregistrer"}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {/* {openNotif && (
        <div className={`notif ${isError ? "notif-error" : "notif-success"}`}>
          <p>{notif}</p>
        </div>
      )} */}
    </>
  );
};

export default PayeLivraison;

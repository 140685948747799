import React, { useState } from "react";
import edit_ico from "../../Assets/Icons/icons8-modifier-50.png";

const EditFournisseur = ({nom_fournisseur, adresse, contact}) => {
  const [openWin, setOpenWin] = useState(false);

  const [nomUpdate, setNomUpdate] = useState(nom_fournisseur);
  const [adresseUpdate, setAdresseUpdate] = useState(adresse);
  const [contactUpdate, setContactUpdate] = useState(contact);

  // Convert the received date_commande to a format suitable for the input
  //  const formattedDate = new Date(date_commande).toISOString().slice(0, 10); // YYYY-MM-DD format

  // mise à jour des donnees dans base
  const handleSubmit = async () => {
    const formData = {
        nom_fournisseur: nomUpdate,
        adresse: adresseUpdate,
        contact: contactUpdate,
      };
  
    try {
      localStorage.setItem("commandeData", JSON.stringify(formData));
      console.log("Data saved to local storage:", formData);
      // Perform any other actions after successful storage
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const Alerter = async () => {
    setOpenWin(true);
  };

  const Annuler = async () => {
    setOpenWin(false);
  };
  return (
    <>
      <button className="btn btn-edit" onClick={Alerter}>
        <img src={edit_ico} alt="edit_ico" />
      </button>
      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">NOUVEAU FOURNISSEUR</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <label htmlFor="nom">Nom :</label>
              <br />
              <input
                type="text"
                name="nom"
                id="nom"
                placeholder="Nom du fournisseur..."
                value={nomUpdate}
                onChange={(e) => setNomUpdate(e.target.value)}
              />
              <br />
              <label htmlFor="adresse">Adresse :</label>
              <br />
              <input
                type="text"
                name="adresse"
                id="adresse"
                placeholder="Adresse..."
                value={adresseUpdate}
                onChange={(e) => setAdresseUpdate(e.target.value)}
              />
              <br />
              <label htmlFor="contact">Contact :</label>
              <br />
              <input
                type="text"
                name="contact"
                id="contact"
                placeholder="Contact..."
                value={contactUpdate}
                onChange={(e) => setContactUpdate(e.target.value)}
              />
              <br />
              <div className="alert-action">
                <button
                  className="btn btn-annuler"
                  type="button"
                  onClick={Annuler}
                >
                  Annuler
                </button>
                <button className="btn btn-confirmer" type="submit">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default EditFournisseur;

import axios from "axios";
import React, { useState } from "react";


const NouvBeneficiere = () => {
  const [openWin, setOpenWin] = useState(false);
  const [nom, setNom] = useState("");


  const [notif, setNotif] = useState("");
  const [openNotif, setOpenNotif] = useState(false);
  const [isError, setIsError] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault(); // Empêche le rechargement de la page
    if (!nom ) {
      notifyError("Tous les champs doivent être remplis.");
      return;
    }

    const formData = {
      nom_cooperative: nom,
    };

    try {
      const response = await axios.post(
        "https://faly-litchi.mg/api/create_cooperative",
        formData
      );
      notifySuccess();
      console.log("Ajout réussi :", response.data);
    } catch (error) {
      notifyError("Erreur lors de l'enregistrement du fournisseur.");
      console.error(
        "Erreur lors de la soumission des données :",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsSubmitting(false);
    }
    setOpenWin(false);
    window.location.reload();
  };

  const Alerter = () => {
    setOpenWin(true);
  };

  const Annuler = () => {
    setOpenWin(false);
  };

  const notifySuccess = () => {
    setOpenWin(!openWin);
    setNotif("Opération réussie !");
    setIsError(false);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  const notifyError = (message) => {
    setOpenWin(!openWin);
    setNotif(message);
    setIsError(true);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  return (
    <>
      <button className="btn-commande menu-filtre" style={{backgroundColor:"#5d1818"}} onClick={Alerter}>
        <div> Nouvelle Beneficiere</div>
      </button>
      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">NOUVEAU COOPERATIVE TARATRA</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form onSubmit={handleSubmit} className="form-popup">
              <label htmlFor="nom">Nom :</label>
              <br />
              <input
                type="text"
                name="nom"
                id="nom"
                placeholder="Nom du beneficiere..."
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />

              <div className="alert-action">
                <button
                  className="btn btn-annuler"
                  type="button"
                  onClick={Annuler}
                >
                  Annuler
                </button>
                <button
                  className="btn btn-confirmer"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Envoi..." : "Enregistrer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Notification */}
      <div className={`container-notif ${openNotif ? "show" : ""}`}>
        {openNotif && (
          <div className={isError ? "error" : "success"}>{notif}</div>
        )}
      </div>
    </>
  );
};

export default NouvBeneficiere;

import React, { useState, useEffect } from "react";
import axios from "axios";
import SelectSearch from "../../Components/SelectSearch/SelectSearch";

const NouvSoufrage = ({ onAjout }) => {
  // Déclarations des états
  const [typeShift, setTypeShift] = useState("");
  const [numShift, setNumShift] = useState();
  const [quantite, setQuantite] = useState();
  const [error, setError] = useState("");
  const [lastIdSouffrage, setLastIdSouffrage] = useState();
  const [livreRestant, setLivreRestant] = useState(0);
  const [totalSouffrage, setTotalSouffrage] = useState(0);
  const [totalQuantiteLivre, setTotalQuantiteLivre] = useState();
  const [date_souffrage, setDate_souffrage] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [fournisseurs, setFournisseurs] = useState([
    { fournisseur: null, quantite: "", box: "" },
  ]);
  const [openWin, setOpenWin] = useState(false);
  const [optionsFournisseurs, setOptionsFournisseurs] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Notifications
  const [notif, setNotif] = useState("");
  const [openNotif, setOpenNotif] = useState(false);
  const [isError, setIsError] = useState(false);

  const shift = ["Jour", "Nuit"];

  // Utilitaire de vérification de numéro
  function isNotNumber(value) {
    return typeof value !== "number" || isNaN(value);
  }

  const handleFournisseurChange = (index, selectedOption) => {
    const updatedFournisseurs = [...fournisseurs];
    updatedFournisseurs[index].fournisseur = selectedOption;
    setFournisseurs(updatedFournisseurs);
  };

  const handleBoxChange = (index, value) => {
    const updatedFournisseurs = [...fournisseurs];
    updatedFournisseurs[index].numero_box = value || ""; // Valeur par défaut si undefined
    setFournisseurs(updatedFournisseurs);
  };

  // Chargement initial des options de fournisseurs
  useEffect(() => {
    const fetchFournisseurs = async () => {
      try {
        const response = await axios.get(
          "https://faly-litchi.mg/api/show_fournisseur"
        );
        const options = response.data.map((fournisseur) => ({
          value: fournisseur.id,
          label: fournisseur.nom_fournisseur,
        }));
        setOptionsFournisseurs(options);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des fournisseurs :",
          error
        );
      }
    };
    fetchFournisseurs();
  }, []);

  // Récupérer le dernier ID de souffrage
  useEffect(() => {
    const fetchLastIdSouffrage = async () => {
      try {
        const response = await axios.get(
          "https://faly-litchi.mg/api/showLastIdSouffrage"
        );
        setLastIdSouffrage(response.data.id);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'ID :", error);
      }
    };
    fetchLastIdSouffrage();
  }, []);

  // Récupérer la quantité totale de souffrage
  useEffect(() => {
    const fetchQuantiteSouffrage = async () => {
      try {
        const response = await axios.get(
          "https://faly-litchi.mg/api/show_quantite_total_souffrage"
        );
        setTotalSouffrage(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la quantité totale :",
          error
        );
      }
    };
    fetchQuantiteSouffrage();
  }, []);

  // Charger les données de compte et calculer les stocks restants
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://faly-litchi.mg/api/show_compte"
      );
      setTotalQuantiteLivre(response.data.nombre_livraison);
      // setLivreRestant(response.data.nombre_livraison - totalSouffrage);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    }
  };

  useEffect(() => {
    fetchData();
      setLivreRestant(totalQuantiteLivre - totalSouffrage);
    if (typeShift === "Jour") setNumShift(2);
    else if (typeShift === "Nuit") setNumShift(1);
  }, [totalQuantiteLivre, totalSouffrage, typeShift]);

  // Ajout d'un fournisseur à la liste
  const handleAddFournisseur = () => {
    setFournisseurs([
      ...fournisseurs,
      { fournisseur: null, quantite: "", box: "" },
    ]);
  };

  // Changer la quantité d'un fournisseur
  const handleCartonsChange = (index, value) => {
    const newFournisseurs = [...fournisseurs];
    newFournisseurs[index].quantite = value;
    setFournisseurs(newFournisseurs);
  };

  // Supprimer un fournisseur de la liste
  const handleRemoveFournisseur = (index) => {
    setFournisseurs(fournisseurs.filter((_, i) => i !== index));
  };

  // Soumission du formulaire
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!typeShift) {
      notifyError("Le type de shift doit être renseigné.");
      return;
    }

    const hasEmptyQuantite = fournisseurs.some(
      (f) => !f.quantite || f.quantite.trim() === ""
    );

    if (hasEmptyQuantite) {
      notifyError("Vous devez compléter la quantité.");
      return;
    }

    if (totalQuantiteSaisis < quantite) {
      notifyError("La quantité n'est pas complétée par des fournisseurs.");
      return;
    }

    const hasEmptyBox = fournisseurs.some(
      (f) => !f.numero_box || f.numero_box.trim() === "" // Vérification de numero_box
    );

    if (hasEmptyQuantite || hasEmptyBox) {
      notifyError("Tous les champs doivent être remplis.");
      return;
    }

    const formData = {
      numero_souffrage: isNotNumber(lastIdSouffrage) ? 1 : lastIdSouffrage,
      shift: numShift,
      quantite,
      date_souffrage,
      fournisseur: fournisseurs
        .map((f) => ({
          fournisseur_id: f.fournisseur?.value,
          quantite_fournisseur: f.quantite,
          numero_box: f.numero_box || "", // On inclut numero_box dans les données
        }))
        .filter((f) => f.fournisseur_id),
    };

    console.log("Données prêtes à être envoyées :", formData);

    try {
      const response = await axios.post(
        "https://faly-litchi.mg/api/create_souffrage_fournisseur",
        formData
      );
      notifySuccess();
      console.log("Ajout réussi :", response.data);
      setOpenWin(false);
      setIsSubmitting(false);
      Initialise();
      window.location.reload();
      onAjout();
    } catch (error) {
      notifyError("Erreur lors de l'enregistrement de la commande.");
      console.error(
        "Erreur lors de la soumission des données :",
        error.response ? error.response.data : error.message
      );
    }
  };

  // Initialiser les valeurs après soumission
  const Initialise = () => {
    setQuantite("");
    setIsSubmitting(false);
  };

  const Alerter = () => setOpenWin(true);
  const Annuler = () => setOpenWin(false);

  const totalQuantiteSaisis = fournisseurs.reduce(
    (acc, fournisseur) => acc + (parseInt(fournisseur.quantite, 10) || 0),
    0
  );

  // const getFilteredOptions = () => {
  //   const selectedValues = fournisseurs
  //     .map((f) => f.fournisseur?.value)
  //     .filter(Boolean);
  //   return optionsFournisseurs.filter(
  //     (option) => !selectedValues.includes(option.value)
  //   );
  // };

  const getFilteredOptions = () => {
    return optionsFournisseurs;
};

  // Notifications
  const notifySuccess = () => {
    setNotif("Opération réussie !");
    setIsError(false);
    setOpenNotif(true);
    setTimeout(() => setOpenNotif(false), 5000);
  };

  const notifyError = (message) => {
    setNotif(message);
    setIsError(true);
    setOpenNotif(true);
    setTimeout(() => setOpenNotif(false), 5000);
  };

  // Validation de la quantité saisie
  const handleQuantite = (e) => {
    const value = e.target.value;
    setQuantite(value);
    if (value * 18 > parseInt(livreRestant, 10)) {
      setError("La quantité ne doit pas être supérieure à la quantité livrée.");
    } else {
      setError("");
    }
  };

  // Formatage des montants
  const formatMontant = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };
  return (
    <>
      {/* Button to open the "Nouvelle Soufrage" modal */}
      <button className="btn-commande menu-filtre" onClick={Alerter}>
        <div> Nouvelle Soufrage</div>
      </button>

      {/* Modal Popup for creating a new Soufrage */}
      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">NOUVELLE SOUFRAGE</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              <div>
                <label>Numéro souffrage:</label>
                <input
                  type="text"
                  value={!isNotNumber(lastIdSouffrage) ? lastIdSouffrage : 1}
                  disabled
                />
              </div>

              <div>
                <label>Date souffrage:</label>
                <input
                  type="date"
                  value={date_souffrage}
                  onChange={(e) => setDate_souffrage(e.target.value)}
                />
              </div>

              <div>
                <label>Jour ou Nuit :</label>
                <select
                  value={typeShift}
                  onChange={(e) => setTypeShift(e.target.value)}
                  required
                >
                  <option value="">Sélectionner...</option>
                  {shift.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>

                <div>
                  <label>Stocks :</label>
                  <input
                    type="text"
                    placeholder="en kg"
                    value={formatMontant(livreRestant) + " kg" || 0}
                    onChange={handleQuantite}
                    disabled
                  />
                </div>

                <div>
                  <label>Nombre caissette à souffrer :</label>
                  <input
                    type="number"
                    placeholder="..."
                    value={quantite}
                    onChange={handleQuantite}
                  />
                </div>
              </div>

              <div
                style={{
                  overflowY: "auto",
                  maxHeight: "150px",
                 minHeight: "110px",
                  marginTop: "6px",
                }}
              >
                {fournisseurs.map((fournisseur, index) => (
                  <div key={index} className="input-quantite-3">
                    <div className="frs">
                      <label>Fournisseurs:</label>
                      <SelectSearch
                        name="fournisseur"
                        options={getFilteredOptions()}
                        value={fournisseur.fournisseur}
                        onChange={(selectedOption) =>
                          handleFournisseurChange(index, selectedOption)
                        }
                        placeholder="Sélectionner un fournisseur"
                        required
                      />

                    </div>

                    <div className="frs">
                      <label>Nb C/te</label>
                      <input
                        type="number"
                        value={fournisseur.quantite}
                        onChange={(e) =>
                          handleCartonsChange(index, e.target.value)
                        }
                        placeholder="Quantité"
                        required
                      />
                    </div>

                    <div className="frs">
                      <label>N° boxe</label>
                      <input
                        type="number"
                        value={fournisseur.numero_box}
                        onChange={(e) => handleBoxChange(index, e.target.value)}
                        placeholder="N° boxe"
                        required
                      />
                    </div>

                    <div className="supp">
                 
                      <input
                      style={{
                        marginTop:"20px"
                      }}
                        type="button"
                        onClick={() => handleRemoveFournisseur(index)}
                        value="x"
                      />
                    </div>
                  </div>
                ))}
              </div>

              {totalQuantiteSaisis < quantite && (
                <button
                  className="btn btn-more"
                  type="button"
                  onClick={handleAddFournisseur}
                >
                  +
                </button>
              )}

              {totalQuantiteSaisis > quantite && (
                <div className="error-message" style={{ color: "red" }}>
                  C'est pas logique
                </div>
              )}
            </form>

            <div className="alert-action">
              <span className="red-error">{error}</span>
              <button className="btn btn-annuler" onClick={Annuler}>
                Annuler
              </button>
              <button
                className="btn btn-confirmer"
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Envoi..." : "Enregistrer"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Notification */}
      <div className={`container-notif ${openNotif ? "show" : "hide"}`}>
        {openNotif && (
          <div className={isError ? "error" : "success"}>{notif}</div>
        )}
      </div>
    </>
  );
};

export default NouvSoufrage;

import React, { useEffect, useState } from "react";
import axios from "axios";
import Chargement from "../../Components/Chargement/Chargement";
import moins from "../../Assets/Icons/icons8-moins-24.png";
import plus from "../../Assets/Icons/icons8-plus-24.png";
import Distrubution from "./Distrubution";
import NouvBeneficiere from "./NouvBeneficiere";
import { Link } from "react-router-dom";
import search_ico from "../../Assets/Icons/icons8-chercher-50.png";
import PrintFactureCooperative from "./PrintFactureCooperative";
import FactureAllCooperative from "./FactureAllCooperative";

const Archives = () => {
  useEffect(() => {
    document.title = "Cooperative";
    fetchData();
  }, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [price, setPrice] = useState();

  useEffect(() => {
    document.title = "Traitement";
  }, []);

  // État pour gérer les éléments sélectionnés
  const [selectedIds, setSelectedIds] = useState([]);

  // État pour la sélection totale
  const [allSelected, setAllSelected] = useState(false);

  // Fonction pour gérer la sélection/désélection de tous les éléments
  const toggleSelectAll = () => {
    if (allSelected) {
      setSelectedIds([]);
    } else {
      // Select all filtered items instead of all data
      setSelectedIds(filteredDistribution);
    }
    setAllSelected(!allSelected);
  };
  const formatNumber = (number) => {
    return number
      ? number.toLocaleString(undefined, { maximumFractionDigits: 2 })
      : "0";
  };
  // Fonction pour sélectionner/désélectionner un élément
  const toggleSelect = (item) => {
    setSelectedIds((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };

  // GET payement

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://faly-litchi.mg/api/show_livraison_cooperative"
      );
      setData(response.data);
      console.log("Données :", response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoading(false);
    }
  };

  // GET price
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://faly-litchi.mg/api/show_price"
        );
        setPrice(response.data.prix);
        console.log("Données :", response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchData();
  }, []);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  // Filtre d'affichage
  const filteredDistribution = data.filter((distribution) => {
    const matchesSearch = distribution.cooperative.nom_cooperative
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesFilterDate =
      filterDate === "" || distribution.date_distribution === filterDate;
    return matchesFilterDate && matchesSearch;
  });

  // Check if any of the filtered payments are selected
  const areAnyFilteredSelected = filteredDistribution.some((row) =>
    selectedIds.includes(row)
  );

  const rafraichirFournisseurs = () => {
    fetchData();
  };

  return (
    <>
      <div className="container">
        <div className="header">
          <div className="cadre-option">
            <h1 className="titre-contenu">DISTRUBUTION COOPERATIVE</h1>
            <div
              className={`option-view ${showFilters ? "view-open" : ""}`}
              onClick={toggleFilters}
            >
              <img src={showFilters ? moins : plus} alt="toggle filtre" />
            </div>
          </div>
          {showFilters && (
            <div className={`head-commande ${showFilters ? "show" : ""}`}>
              <div className="menu-filtre-search">
                <input
                  type="text"
                  placeholder="Recherche..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm === "" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      backgroundColor: "#127ee3",
                      padding: "5px",
                       marginTop:"5px"
                    }}
                  >
                    <img
                      style={{ width: "19px" }}
                      src={search_ico}
                      alt="search icon"
                    />
                  </div>
                )}
              </div>
              <Distrubution onAjout={rafraichirFournisseurs} />
              <NouvBeneficiere />
              <Link to={"beneficiere"}>
                <button
                  className="btn-commande menu-filtre"
                  style={{ backgroundColor: "#af8700" }}
                >
                  <div> Voir les Beneficiere</div>
                </button>
              </Link>
              <div
                className=""
                style={{
                  pointerEvents: areAnyFilteredSelected ? "auto" : "none",
                }}
              >
                <FactureAllCooperative
                  selectedIds={selectedIds.filter((id) =>
                    filteredDistribution.includes(id)
                  )}
                />
              </div>
              <input
                className="menu-filtre"
                type="date"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)} // Update filterDate state
              />
            </div>
          )}
        </div>
        <div className="cadre-table-scroll">
          {loading ? (
            <Chargement />
          ) : (
            <table>
              <thead>
                <tr>
                  <th className="th-id">
                    <input
                      type="checkbox"
                      checked={allSelected}
                      onChange={toggleSelectAll}
                    />
                  </th>
                  <th className="th-date text-center">Date</th>
                  <th className="">Beneficiaire</th>
                  <th className="text-end">Quantité</th>
                  <th className="text-end">PU (AR)</th>
                  <th className="text-end">Montant (Ar)</th>
                  <th className="th-id text-center">Options</th>
                </tr>
              </thead>
              <tbody>
                {filteredDistribution.length > 0 ? (
                  filteredDistribution.map((row) => (
                    <tr key={row.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedIds.includes(row)}
                          onChange={() => toggleSelect(row)}
                        />
                      </td>
                      <td className="text-center">{row.date_distribution}</td>
                      <td className="">{row.cooperative.nom_cooperative}</td>
                      <td className="text-end">{row.quantite} kg</td>
                      <td className="text-end">{formatNumber(price)} Ar</td>
                      <td className="text-end color-money">
                        {formatNumber(price * row.quantite)} Ar
                      </td>
                      <td>
                        <div className="option">
                          <PrintFactureCooperative
                            date_distribution={row.date_distribution}
                            id={row.id}
                            quantite={row.quantite}
                            nom_cooperative={row.cooperative.nom_cooperative}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      Aucun résultat
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Archives;

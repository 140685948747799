import React, { useState, useEffect } from "react";
import axios from "axios";
import "./traitement.css";
import SelectSearch from "../../Components/SelectSearch/SelectSearch";

const PaletteForm = ({ onAjout }) => {
  const [typePalette, setTypePalette] = useState("");
  const [totalCarton, setTotalCarton] = useState(0);
  const [fournisseurs, setFournisseurs] = useState([
    { fournisseur: null, cartons: "" },
  ]);
  const [openWin, setOpenWin] = useState(false);
  const [optionsFournisseurs, setOptionsFournisseurs] = useState([]);
  const [lastIdPalette, setLastIdPalette] = useState(1);

  const typesPalette = ["2", "5.5"];

  // NOTIFICATIONS
  const [notif, setNotif] = useState("");
  const [openNotif, setOpenNotif] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function isNotNumber(value) {
    return typeof value !== "number" || isNaN(value);
  }

  // GET LAST ID COMMANDE
  useEffect(() => {
    const fetchLastIdCommande = async () => {
      try {
        const url = "https://faly-litchi.mg/api/show_lastIdPalette";
        const response = await axios.get(url);
        setLastIdPalette(response.data.id + 1);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'ID :", error);
      }
    };

    fetchLastIdCommande();
  }, []);

  useEffect(() => {
    const fetchFournisseurs = async () => {
      try {
        const url = "https://faly-litchi.mg/api/show_fournisseur";
        const response = await axios.get(url);
        const options = response.data.map((fournisseur) => ({
          value: fournisseur.id,
          label: fournisseur.nom_fournisseur,
        }));
        setOptionsFournisseurs(options);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des fournisseurs :",
          error
        );
      }
    };

    fetchFournisseurs();
  }, []);

  useEffect(() => {
    if (typePalette === "2") {
      setTotalCarton(440);
    } else if (typePalette === "5.5") {
      setTotalCarton(180);
    } else {
      setTotalCarton(0);
    }
  }, [typePalette]);

  const handleAddFournisseur = () => {
    setFournisseurs([...fournisseurs, { fournisseur: null, cartons: "" }]);
  };

  const handleFournisseurChange = (index, selectedOption) => {
    const newFournisseurs = [...fournisseurs];
    newFournisseurs[index].fournisseur = selectedOption;
    setFournisseurs(newFournisseurs);
  };

  const handleCartonsChange = (index, value) => {
    const newFournisseurs = [...fournisseurs];
    newFournisseurs[index].cartons = value;
    setFournisseurs(newFournisseurs);
  };

  const handleRemoveFournisseur = (index) => {
    const newFournisseurs = fournisseurs.filter((_, i) => i !== index);
    setFournisseurs(newFournisseurs);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Vérifie si le numéro de palette et le type de palette sont vides
    if (!typePalette) {
      notifyError("le type de palette doivent être renseignés");
      console.error(
        "Erreur : Le numéro de palette et le type de palette doivent être renseignés."
      );
      return;
    }

    // Vérifie si au moins un carton est vide
    const hasEmptyCartons = fournisseurs.some(
      (f) => !f.cartons || f.cartons.trim() === ""
    );

    if (hasEmptyCartons) {
      notifyError("Au moins un nombre de cartons est vide.");
      console.error("Erreur : Au moins un nombre de cartons est vide.");
      return;
    }

    const hasEmptyFournisseur = fournisseurs.some((f) => !f.fournisseur);
    if (hasEmptyFournisseur) {
      notifyError("Veuillez sélectionner un fournisseur pour chaque ligne.");
      return;
    }

    if (totalCartonsSaisis < totalCarton || totalCartonsSaisis > totalCarton) {
      notifyError("le nombre de carton doit être rempli");
      return;
    }

    // Préparation des données à envoyer
    setIsSubmitting(true);
    const formData = {
      numero_p: !isNotNumber(lastIdPalette) ? lastIdPalette : 1,
      type: parseFloat(typePalette),
      nombre_carton: totalCarton,
      fournisseur: fournisseurs
        .map((f) => ({
          fournisseur_id: f.fournisseur?.value, // Vérifie si le fournisseur est sélectionné
          nombre_carton_fournisseur: f.cartons,
        }))
        .filter((f) => f.fournisseur_id), // Filtrer les fournisseurs sans id
    };
    console.log(formData);

    try {
      const response = await axios.post(
        "https://faly-litchi.mg/api/create_palette_fournisseur",
        formData
      );
      notifySuccess();
      setOpenWin(false);
      console.log("Ajout réussi :", response.data);
      setIsSubmitting(false);
      // Initialise();
      onAjout();
    } catch (error) {
      notifyError("Erreur lors de l'enregistrement de la commande.");
      console.error(
        "Erreur lors de la soumission des données :",
        error.response ? error.response.data : error.message
      );
    }
  };

  const Alerter = () => setOpenWin(true);
  const Annuler = () => {
    setIsSubmitting(false);
    setOpenWin(false);
  };

  const totalCartonsSaisis = fournisseurs.reduce((acc, fournisseur) => {
    return acc + (parseInt(fournisseur.cartons) || 0);
  }, 0);

  const getFilteredOptions = () => {
    const selectedValues = fournisseurs
      .map((f) => f.fournisseur?.value)
      .filter(Boolean);
    return optionsFournisseurs.filter(
      (option) => !selectedValues.includes(option.value)
    );
  };

  const notifySuccess = () => {
    setNotif("Opération réussie !");
    setIsError(false);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  const notifyError = (message) => {
    setNotif(message);
    setIsError(true);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  return (
    <>
      <button className="btn-commande menu-filtre" onClick={Alerter}>
        <div> Nouvelle Palette</div>
      </button>
      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">NOUVELLE PALETTE</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Numéro Palette:</label>
                <input
                  type="text"
                  value={!isNotNumber(lastIdPalette) ? lastIdPalette : 1}
                  disabled
                />
              </div>
              <div>
                <label>Type Palette:</label>
                <select
                  value={typePalette}
                  onChange={(e) => setTypePalette(e.target.value)}
                  required
                >
                  <option value="">Sélectionner un type</option>
                  {typesPalette.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                <label>Total Cartons :</label>
                <input
                  type="text"
                  value={totalCarton}
                  onChange={(e) => setTotalCarton(e.target.value)}
                />
              </div>

              <div>
                <label>Fournisseurs:</label>
                <br />
                {fournisseurs.map((fournisseur, index) => (
                  <div key={index} className="input-quantite-3">
                    <div className="frs">
                      <SelectSearch
                        name="fournisseur"
                        options={getFilteredOptions()}
                        value={fournisseur.fournisseur}
                        onChange={(selectedOption) =>
                          handleFournisseurChange(index, selectedOption)
                        }
                        placeholder="Sélectionner un fournisseur"
                        isClearable
                        required
                      />
                    </div>
                    <div className="frs">
                      <input
                        type="number"
                        name="cartons"
                        value={fournisseur.cartons}
                        onChange={(e) =>
                          handleCartonsChange(index, e.target.value)
                        }
                        placeholder="Nombre de cartons"
                        required
                      />
                    </div>
                    <div className="supp">
                      <input
                        type="button"
                        onClick={() => handleRemoveFournisseur(index)}
                        value={"x"}
                      />
                    </div>
                  </div>
                ))}
                {totalCartonsSaisis < totalCarton && (
                  <button
                    className="btn btn-more"
                    type="button"
                    onClick={handleAddFournisseur}
                  >
                    +
                  </button>
                )}
              </div>

              {totalCartonsSaisis > totalCarton && (
                <div className="error-message" style={{ color: "red" }}>
                  C'est pas logique
                </div>
              )}
            </form>
            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Annuler
              </button>
              <button
                className="btn btn-confirmer"
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Envoi..." : "Enregistrer"}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Notification */}
      <div className={`container-notif ${openNotif ? "show" : "hide"}`}>
        {openNotif && (
          <div className={isError ? "error" : "success"}>{notif}</div>
        )}
      </div>
    </>
  );
};

export default PaletteForm;

import React, { useState } from "react";
import "./sidebar.css";
import oeil_ouvert from "../../Assets/Icons/oeil-ouvert (1).png";
import oeil_ferme from "../../Assets/Icons/icons8-cacher-50 (1).png";
import { NavLink } from "react-router-dom";
import dashboard_ico from "../../Assets/Icons/icons8-tableau-de-bord-50.png";
import commander_ico from "../../Assets/Icons/icons8-commande-50 (1).png";
import fournisseur_ico from "../../Assets/Icons/icons8-fournisseur-50.png";
import payement_ico from "../../Assets/Icons/icons8-cartes-bancaires-50.png";
import traitement_ico from "../../Assets/Icons/icons8-litchi-50.png";
import palette_ico from "../../Assets/Icons/icons8-commande-50 (1).png";
import stock_ico from "../../Assets/Icons/icons8-stockage-50.png";
import archive_ico from "../../Assets/Icons/icons8-archives-50.png";


const Sidebar = ({ closeSidebar, toggleSidebar }) => {
  const [isOpen, setIsOpen] = useState(closeSidebar);


  // Met à jour l'état de la sidebar en fonction des props
  React.useEffect(() => {
    setIsOpen(closeSidebar);
  }, [closeSidebar]);

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
    toggleSidebar();
  };

  return (
    <>
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="navigation">
          <div>NAVIGATION</div>
          <img
            src={isOpen ? oeil_ouvert : oeil_ferme}
            onClick={handleOpen}
            alt="oeil"
          />
        </div>
        <div className="menubar">
          <ul className="menubar-items">
            <NavLink to={"/dashboard"}>
              <li>
                <img src={dashboard_ico} alt="dashboard_ico" />{" "}
                <label htmlFor="">Tableau de bord</label>
              </li>
            </NavLink>
            <NavLink to={"fournisseur"}>
              <li>
                <img src={fournisseur_ico} alt="fournisseur_ico" />{" "}
                <label htmlFor="">Fournisseurs</label>
              </li>
            </NavLink>
            <NavLink to={"avance"}>
              <li>
                <img src={payement_ico} alt="payement_ico" />{" "}
                <label htmlFor="">Avances</label>
              </li>
            </NavLink>
          
            <NavLink to={"commande"}>
              <li>
                <img src={commander_ico} alt="commander_ico" />{" "}
                <label htmlFor="">Commander Letchis</label>
              </li>
            </NavLink>
            <NavLink to={"stock"}>
              <li>
                <img src={stock_ico} alt="stock_ico" />{" "}
                <label htmlFor="">Livraisons</label>
              </li>
            </NavLink>
            <NavLink to={"payement"}>
              <li>
                <img src={payement_ico} alt="payement_ico" />{" "}
                <label htmlFor="">Payements</label>
              </li>
            </NavLink>
            <NavLink to={"soufrage"}>
              <li>
                <img src={traitement_ico} alt="soufrage" />
                <label htmlFor="">Souffrage</label>
              </li>
            </NavLink>
            <NavLink to={"traitement"}>
              <li>
                <img src={traitement_ico} alt="traitement_ico" />{" "}
                <label htmlFor="">Suivi traitement Letchis</label>
              </li>
            </NavLink>

            <NavLink to={"archives"}>
              <li>
                <img src={archive_ico} alt="archive_ico" />{" "}
                <label htmlFor="">Coopereative TARATRA</label>
              </li>
            </NavLink>
           
            <NavLink to={"performance"}>
              <li>
                <img src={fournisseur_ico} alt="performance" />{" "}
                <label htmlFor="">Performance</label>
              </li>
            </NavLink>
            <NavLink to={"palette"}>
              <li>
                <img src={palette_ico} alt="palette_ico" />{" "}
                <label htmlFor="">Rapport</label>
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

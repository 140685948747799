import React, { useEffect, useState } from "react";
import "./traitement.css";
import PaletteForm from "./PaletteForm";
import axios from "axios";
import Chargement from "../../Components/Chargement/Chargement";
import moins from "../../Assets/Icons/icons8-moins-24.png";
import plus from "../../Assets/Icons/icons8-plus-24.png";
import DetailPalette from "./DetailPalette";

const Traitement = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(true);

  useEffect(() => {
    document.title = "Traitement";
  }, []);

  // GET payement

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://faly-litchi.mg/api/show_palette"
      );
      setData(response.data.palette);
      console.log("Données :", response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const rafraichirFournisseurs = () => {
    fetchData();
  };

  const formatNumber = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };


  return (
    <>
      <div className="container">
        <div className="header">
          <div className="cadre-option">
            <h1 className="titre-contenu">Liste des palettes</h1>
            <div
              className={`option-view ${showFilters ? "view-open" : ""}`}
              onClick={toggleFilters}
            >
              <img src={showFilters ? moins : plus} alt="toggle filtre" />
            </div>
          </div>
          {showFilters && (
            <div className={`head-commande ${showFilters ? "show" : ""}`}>
              <PaletteForm onAjout={rafraichirFournisseurs}/>
            </div>
          )}
        </div>
        <div className="cadre-table-scroll">
          {loading ? (
            <Chargement />
          ) : (
            <table>
              <thead>
                <tr>
                  <th className="th-contact text-center">N° Palette</th>
                  <th className="text-end">Type Palette</th>
                  <th className="text-end">Nombre Fournisseurs</th>
                  <th className="text-end">Total Carton</th>
                  <th className="text-end">Quantité</th>
                  <th className="th-id text-center">Options</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((row) => (
                    <tr key={row.id}>
                      <td className="text-center">N° {row.id}</td>
                      <td className="text-end">{row.type} kg</td>
                      <td className="text-end">
                        {row.palette_fournisseurs_count}
                      </td>
                      <td className="text-end">{row.nombre_carton}</td>
                      <td className="text-end">{formatNumber(parseFloat(row.type)*row.nombre_carton)} kg</td>
                      <td>
                        <div className="option">
                          <DetailPalette
                            numPalette={row.id}
                            type={row.type}
                            nombreCarton={row.nombre_carton}
                            nombreFournisseur={row.palette_fournisseurs_count}
                            fournisseurs={row.palette_fournisseurs.map((f) => ({
                              nom: f.fournisseur.nom_fournisseur,
                              nombreCarton: f.nombre_carton,
                            }))}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      Aucun résultat
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Traitement;

import React, { useState } from "react";

const DetailSouffrage = ({
  numSouffrage,
  shift,
  quantite,
  nom_fournisseur,
  date_souffrage,
  numero_de_lot,
  numero_box,
}) => {
  // State to manage popup visibility
  const [openWin, setOpenWin] = useState(false);

  // Open popup
  const Alerter = () => {
    setOpenWin(true);
  };

  // Close popup
  const Annuler = () => {
    setOpenWin(false);
  };

  return (
    <>
      <button className="btn btn-print" onClick={Alerter}>
        <label>Details</label>
      </button>

      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">Détails de la palette</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>

            <div className="detail-palette">
              <table>
                <tbody>
                <tr>
                    <td
                      style={{ backgroundColor: "#535353", color: "#d9d9d9" }}
                    >
                      Numéro de Lot
                    </td>
                    <td className="text-end">{numero_de_lot}</td>
                  </tr>
                  <tr>
                    <td
                      style={{ backgroundColor: "#535353", color: "#d9d9d9" }}
                    >
                      Numéro de souffrage
                    </td>
                    <td className="text-end">{numSouffrage}</td>
                  </tr>
                  <tr>
                    <td
                      style={{ backgroundColor: "#535353", color: "#d9d9d9" }}
                    >
                      Numéro de box
                    </td>
                    <td className="text-end">{numero_box}</td>
                  </tr>
                  <tr>
                    <td
                      style={{ backgroundColor: "#535353", color: "#d9d9d9" }}
                    >
                      Type de shift{" "}
                    </td>
                    <td className="text-end">{shift}</td>
                  </tr>
                  <tr>
                    <td
                      style={{ backgroundColor: "#535353", color: "#d9d9d9" }}
                    >
                      Date souffrage{" "}
                    </td>
                    <td className="text-end">{date_souffrage}</td>
                  </tr>
                  <tr>
                    <td
                      style={{ backgroundColor: "#535353", color: "#d9d9d9" }}
                    >
                      Nombre caissette
                    </td>
                    <td className="text-end">{quantite}</td>
                  </tr>
                  <tr>
                    <td
                      style={{ backgroundColor: "#535353", color: "#d9d9d9" }}
                    >
                      Fournisseur
                    </td>
                    <td className="text-end">{nom_fournisseur}</td>
                  </tr>
                </tbody>
                <thead></thead>
              </table>
            </div>
            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailSouffrage;

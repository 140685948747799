import React from "react";
import "./Chargement.css";

const Chargement = () => {
  return (
    <div className="container-c">
      <div className="row cf">
        <div className="three col">
          <div className="loader" id="loader-3"></div>
          Chargement...
        </div>
      </div>
    </div>
  );
};

export default Chargement;

import axios from "axios";
import React, { useEffect, useState } from "react";
import NouvBeneficiere from "./NouvBeneficiere";
import moins from "../../Assets/Icons/icons8-moins-24.png";
import plus from "../../Assets/Icons/icons8-plus-24.png";
import Distrubution from "./Distrubution";
import Chargement from "../../Components/Chargement/Chargement";
import { Link } from "react-router-dom";

const ListeBeneficiere = () => {
  // Pour Titre
  useEffect(() => {
    document.title = "beneficiere";
  }, []);

  // Les données
  const [beneficiere, setBeneficiere] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(true);

  // GET beneficiere
  useEffect(() => {
    // Via BACKEND
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://faly-litchi.mg/api/show_cooperative"
        );
        setBeneficiere(response.data);
        console.log("données :", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <>
      <div className="container">
        <div className="header">
          <div className="cadre-option">
            <h1 className="titre-contenu">DISTRUBUTION COOPERATIVE</h1>
            <div
              className={`option-view ${showFilters ? "view-open" : ""}`}
              onClick={toggleFilters}
            >
              <img src={showFilters ? moins : plus} alt="toggle filtre" />
            </div>
          </div>
          {showFilters && (
            <div className={`head-commande ${showFilters ? "show" : ""}`}>
              <Distrubution />
              <NouvBeneficiere />
              <Link to={"../archives"}>
                <button className="btn-commande menu-filtre">
                  <div> Voir les Distribution</div>
                </button>
              </Link>
            </div>
          )}
        </div>
        <div className="cadre-table-scroll">
          {loading ? (
            <Chargement />
          ) : (
            <table>
              <thead>
                <tr>
                  <th className="th-id text-center">Id</th>
                  <th>Nom du beneficiere</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {beneficiere.length > 0 ? (
                  beneficiere.map((row) => (
                    <tr key={row.id}>
                      <td>{row.id}</td>
                      <td>{row.nom_cooperative}</td>
                      <td></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9">Aucun resultat</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default ListeBeneficiere;

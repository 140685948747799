import axios from "axios";
import React, { useEffect, useState } from "react";
import moins from "../../Assets/Icons/icons8-moins-24.png";
import plus from "../../Assets/Icons/icons8-plus-24.png";
import search_ico from "../../Assets/Icons/icons8-chercher-50.png";
import Chargement from "../../Components/Chargement/Chargement";

const Performance = () => {
  useEffect(() => {
    document.title = "Performance";
  }, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [showFilters, setShowFilters] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://faly-litchi.mg/api/show_performance"
      );
      setData(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatNumber = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  const filteredPerformance = data.filter((performance) => {
    const matchesSearch = performance.nom_fournisseur
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesSearchId = performance.fournisseur_id
      .toString()
      .includes(searchTerm);

    const matchesFilterDate =
      filterDate === "" || performance.date_livraison === filterDate;
    return (matchesSearch || matchesSearchId) && matchesFilterDate;
  });

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <div className="container">
      <div className="header">
        <div className="cadre-option">
          <h1 className="titre-contenu">Performance Fournisseurs</h1>
          <div
            className={`option-view ${showFilters ? "view-open" : ""}`}
            onClick={toggleFilters}
          >
            <img src={showFilters ? moins : plus} alt="toggle filtre" />
          </div>
        </div>

        {showFilters && (
          <div className="head-commande">
            <div className="menu-filtre-search">
              <input
                type="text"
                placeholder="Recherche..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {!searchTerm && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#127ee3",
                    padding: "5px",
                    marginTop:"5px"
                  }}
                >
                  <img
                    style={{ width: "19px" }}
                    src={search_ico}
                    alt="search icon"
                  />
                </div>
              )}
            </div>

            <input
              className="menu-filtre"
              type="date"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
            />
          </div>
        )}
      </div>

      <div className="cadre-table-scroll">
        {loading ? (
          <Chargement />
        ) : (
          <table>
            <thead>
              <tr>
                <th className="th-id text-center">Id</th>
                <th className="th-date text-center">Date</th>
                <th>Fournisseur</th>
                <th className="text-end">Quantité Livrée (kg)</th>
                <th className="text-end">Montant (Ar)</th>
                <th className="text-end">Déchets (%)</th>
                <th className="th-id text-center">Options</th>
              </tr>
            </thead>
            <tbody>
              {filteredPerformance.length > 0 ? (
                filteredPerformance.map((row) => (
                  <tr key={row.id}>
                    <td className="text-center">{row.fournisseur_id < 10 ? `F0${row.fournisseur_id}` : `F${row.fournisseur_id}`}</td>
                    <td className="text-center">{row.date_livraison}</td>
                    <td>{row.nom_fournisseur}</td>
                    <td className="text-end">
                      {formatNumber(row.quantite_total)} kg
                    </td>
                    <td className="text-end color-money">
                      {formatNumber(row.montant_total_paye)} Ar
                    </td>
                    <td className="text-end">{formatNumber(row.pourcentage_dechet_moyen)} %</td>
                    <td>
                      <div className="option"></div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    Aucun résultat
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Performance;

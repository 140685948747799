import React, { useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import logout_ico from "../../Assets/Icons/icons8-fermer-50.png";

const Logout = () => {
  const { logout } = useAuth();
  // State variables
  const [openWin, setOpenWin] = useState(false);

  // Open popup
  const Alerter = () => {
    setOpenWin(true);
  };

  // Close popup and reset values
  const Annuler = () => {
    setOpenWin(false);
  };

  return (
    <>
      <button className="btn-ico" onClick={Alerter}>
        <img src={logout_ico} alt="logout_ico" />
      </button>

      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">Déconnection</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <p>Êtes-vous sûr de vouloir vous déconnecter ?</p>
            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Annuler
              </button>
              <button className="btn btn-confirmer" onClick={logout}>
                Confirmer
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Logout;

import React, { useEffect, useState } from "react";
import SelectSearch from "../../Components/SelectSearch/SelectSearch";
import axios from "axios";

const NouvLivraison = ({ onAjout }) => {
  // MANIPULATION
  const [openWin, setOpenWin] = useState(false);
  const [openPayement, setOpenPayement] = useState(false);

  // LES DONNEES
  const [commandeNo, setCommandeNo] = useState([]);
  const [dateLivraison, setDateLivraison] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const [montant, setMontant] = useState("");
  const [modePayement, setModePayement] = useState("");
  const [sousFournisseur, setSousFournisseur] = useState(null);
  const [quantiteLivree, setQuantiteLivree] = useState(0);
  const [fournisseur_id, setFournisseur_id] = useState();
  const [nbCaissette, setNbCaissette] = useState(0);
  const [quantiteCommande, setQuantiteCommande] = useState(0);
  const [quantiteCommandeLivree, setQuantiteCommandeLivree] = useState(0);
  const [quantiteRestante, setQuantiteRestante] = useState(0);
  const [commandeId, setCommandeId] = useState(null);
  const [sousFournisseurs, setSousFournisseurs] = useState([]);
  const [choixFournisseur, setChoixFournisseur] = useState("fournisseur");
  const [showNewSousFournisseurInput, setShowNewSousFournisseurInput] =
    useState(false);
  const [newSousFournisseurName, setNewSousFournisseurName] = useState("");

  // NOTIFICATIONS
  const [notif, setNotif] = useState("");
  const [openNotif, setOpenNotif] = useState(false);
  const [isError, setIsError] = useState(false);
  const [logicError, setLogicError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setQuantiteRestante(quantiteCommande - quantiteCommandeLivree);
    setLogicError(quantiteLivree > quantiteRestante);
  }, [
    quantiteLivree,
    quantiteCommande,
    quantiteCommandeLivree,
    quantiteRestante,
  ]);

  const initialise = () => {
    setIsSubmitting(false);
    setDateLivraison(new Date().toISOString().slice(0, 10));
    setQuantiteLivree(0);
    setQuantiteCommande(0);
    setNbCaissette(0);
    setQuantiteRestante(0);
  };

  const notifySuccess = () => {
    setNotif("Opération réussie !");
    setIsError(false);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
    initialise();
  };

  const notifyError = (message) => {
    setNotif(message);
    setIsError(true);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  useEffect(() => {
    const fetchCommande = async () => {
      try {
        const url =
          "https://faly-litchi.mg/api/show_commande_non_livre";
        const response = await axios.get(url);
        if (Array.isArray(response.data)) {
          const options = response.data.map((commande) => ({
            value: commande.id,
            label: `${
              commande.id < 10 ? `C0${commande.id}` : `C${commande.id}`
            } / par : ${
              commande.nom_sous_fournisseur
                ? `${commande.nom_sous_fournisseur} (sous fournisseur)`
                : commande.fournisseur.nom_fournisseur
            }`,
          }));
          setCommandeNo(options);
        } else {
          console.error("Response data is not an array:", response.data);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des commandes non livrées :",
          error
        );
      }
    };

    fetchCommande();
  }, []);

  useEffect(() => {
    const fetchCommandeDetails = async () => {
      if (commandeId) {
        try {
          const url = `https://faly-litchi.mg/api/details_commande/${commandeId}`;
          const response = await axios.get(url);
          setFournisseur_id(response.data.fournisseur_id);
          setQuantiteCommande(response.data.quantite_commande);
          setQuantiteCommandeLivree(response.data.quantite_livre);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des détails de la commande :",
            error
          );
        }
      }
    };
    fetchCommandeDetails();
  }, [commandeId]);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    // Validation
    if (!commandeId) {
      notifyError("Veuillez selectionner une commande");
      setIsSubmitting(false);
      return;
    }
    if (choixFournisseur !== "fournisseur") {
      if (sousFournisseur === null && newSousFournisseurName === "") {
        notifyError(
          "Veuillez sélectionner un sous-fournisseur ou ajouter un nouveau"
        );
        setIsSubmitting(false);
        return;
      }
    }
    if (quantiteLivree <= 0) {
      notifyError("Veuillez entrer la quantité");
      setIsSubmitting(false);
      return;
    }

    if (logicError) {
      setIsSubmitting(false);
      notifyError(
        "Quantité livrée ne peut pas être supérieure à la quantité commandée."
      );
      return;
    }

    const formData = {
      commande_id: commandeId,
      date_livraison: dateLivraison,
      quantite: parseFloat(quantiteLivree),
      nombre_caissette: parseFloat(nbCaissette),
      sous_fournisseur_id: sousFournisseur,
      fournisseur_id: fournisseur_id,
      new_sous_fournisseur: newSousFournisseurName,
    };
    console.log("livraison", formData);
    try {
      const response = await axios.post(
        "https://faly-litchi.mg/api/create_livraison",
        formData
      );
      notifySuccess();
      console.log("Ajout réussi :", response.data);
      setOpenWin(false);
      setIsSubmitting(false);
      initialise();
      onAjout();
      window.location.reload();
    } catch (error) {
      notifyError("Erreur lors de l'enregistrement de la commande.");
      console.error(
        "Erreur lors de la soumission des données :",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Récupérer les sous-fournisseurs
  useEffect(() => {
    const fetchSousFournisseurs = async () => {
      try {
        const response = await axios.get(
          "https://faly-litchi.mg/api/show_sous_fournisseur"
        );
        setSousFournisseurs(
          response.data.map((s) => ({
            value: s.id,
            label: s.nom_sous_fournisseur,
          }))
        );
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des sous-fournisseurs:",
          error
        );
      }
    };

    if (choixFournisseur === "sousfournisseur") {
      fetchSousFournisseurs();
    }
  }, [choixFournisseur]);

  const handleSubmitFacture = async () => {
    const formData = {
      commande_id: commandeNo,
      date_livraison: dateLivraison,
      quantite_livre: quantiteLivree,
      sous_fournisseur: sousFournisseur,
    };

    console.log("Data submitted:", formData);
    setOpenPayement(false);
  };

  const Alerter = () => setOpenWin(true);
  const Annuler = () => {
    setOpenWin(false);
    setOpenPayement(false);
    initialise();
  };

  const handleSelectChange = (option) => {
    setCommandeId(option.value);
  };

  const formatNumber = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  // const generatePDF = () => {
  //   const tableau = document.getElementById("divToPrint");
  //   const nouvelleFenetre = window.open("", "_blank");
  //   const css = `
  //     @media print {
  //       table {
  //         border-collapse: collapse;
  //         width: 100%;
  //       }
  //       th, td {
  //         border: 1px solid #101010;
  //         padding: 4px;
  //         text-align: left;
  //       }
  //       .table-facture thead {
  //         background-color: #b25e5e;
  //         color: white;
  //       }
  //     }
  //   `;
  //   nouvelleFenetre.document.head.innerHTML += `<style>${css}</style>`;
  //   nouvelleFenetre.document.body.innerHTML = tableau.outerHTML;
  //   nouvelleFenetre.focus();
  //   nouvelleFenetre.print();
  //   nouvelleFenetre.close();
  // };

  // Ajouter une option pour désélectionner
  const optionsWithClear = [
    { value: null, label: "Autre sous fournisseur" }, // Option pour désélectionner
    ...sousFournisseurs,
  ];

  useEffect(() => {
    if (sousFournisseur === null) {
      setShowNewSousFournisseurInput(true);
    } else {
      setShowNewSousFournisseurInput(false);
    }
  }, [sousFournisseur]);

  const handleNbCaissette = (e) => {
    setNbCaissette(e.target.value);
    if (e.target.value * 18 > quantiteRestante) {
      setIsSubmitting(false);
      setError("Commande insuffisant !");
    } else {
      setError("");
    }
    setQuantiteLivree(e.target.value * 18);
  };

  return (
    <>
      <button className="btn btn-commande" onClick={Alerter}>
        <div>Nouvelle Livraison</div>
      </button>

      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">NOUVELLE LIVRAISON</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form className="form-popup">
              <label htmlFor="">Commande N° :</label>
              <SelectSearch
                options={commandeNo}
                onChange={handleSelectChange}
              />
              <label htmlFor="">Date de livraison :</label>
              <input
                type="date"
                value={dateLivraison}
                onChange={(e) => setDateLivraison(e.target.value)}
              />
              <label htmlFor="">Quantité commandée restante : </label>
              <input
                type="text"
                value={`${formatNumber(quantiteRestante)} kg`}
                disabled
              />
              <br />
              <label>Par :</label>
              <select
                value={choixFournisseur}
                onChange={(e) => setChoixFournisseur(e.target.value)}
              >
                <option value="fournisseur">Par fournisseur certifié</option>
                <option value="sousfournisseur">
                  Par autre fournisseur non certifié
                </option>
              </select>
              {choixFournisseur === "sousfournisseur" && (
                <>
                  <label>Choisissez votre sous-fournisseur :</label>
                  <SelectSearch
                    options={optionsWithClear}
                    value={sousFournisseur} // Si null, pas de sélection
                    onChange={(option) => {
                      // Si l'option choisie est "Autre sous fournisseur", on met sousFournisseur à null
                      setSousFournisseur(option ? option.value : null);
                    }}
                  />

                  {showNewSousFournisseurInput && (
                    <div>
                      <label htmlFor="">Entrer le nouveau nom</label>
                      <input
                        type="text"
                        placeholder="Nom du sous-fournisseur"
                        value={newSousFournisseurName}
                        onChange={(e) =>
                          setNewSousFournisseurName(e.target.value)
                        }
                      />
                    </div>
                  )}
                </>
              )}
              <div className="">
                <label htmlFor="">Nombre Caissette livrée :</label>
                <input
                  type="number"
                  value={nbCaissette}
                  onChange={handleNbCaissette}
                  placeholder="Nombre caissete"
                />
              </div>
              <div className="">
                <label htmlFor="">Quantité livrée (kg) :</label>
                <input
                  type="text"
                  value={quantiteLivree}
                  // onChange={quantiteHandle}
                  placeholder="En kg"
                  disabled
                />
                <span className="red-error">{error}</span>
              </div>
            </form>
            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Annuler
              </button>
              <button
                className="btn btn-confirmer"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Envoi..." : "Enregistrer"}
              </button>
            </div>
          </div>
        </div>
      )}

      {openPayement && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">FACTURE DE LIVRAISON</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form className="form-popup">
              <label htmlFor="">Fournisseur :</label>
              {/* <input type="text" value={fournisseur} disabled /> */}
              <label htmlFor="">Mode de payement :</label>
              <select
                value={modePayement}
                onChange={(e) => setModePayement(e.target.value)}
              >
                <option value="">Sélectionner</option>
                <option value="cash">Cash</option>
                <option value="chèque">Chèque</option>
                <option value="virement">Virement</option>
              </select>

              <label htmlFor="">Montant :</label>
              <input
                type="number"
                value={montant}
                onChange={(e) => setMontant(e.target.value)}
              />
            </form>

            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Annuler
              </button>
              <button
                className="btn btn-confirmer"
                onClick={handleSubmitFacture}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Notification */}
      <div className={`container-notif ${openNotif ? "show" : "hide"}`}>
        {openNotif && (
          <div className={isError ? "error" : "success"}>{notif}</div>
        )}
      </div>
    </>
  );
};

export default NouvLivraison;

import React, { useEffect, useState } from "react";
import NouvAvance from "./NouvAvance";
import axios from "axios";
import Chargement from "../../Components/Chargement/Chargement";
import moins from "../../Assets/Icons/icons8-moins-24.png";
import plus from "../../Assets/Icons/icons8-plus-24.png";
import search_ico from "../../Assets/Icons/icons8-chercher-50.png";

const Avance = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterDate, setFilterDate] = useState("");

  useEffect(() => {
    document.title = "Avance";
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://faly-litchi.mg/api/show_avance"
      );
      setData(response.data);
      console.log("données :", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Formatage montant
  const formatNumber = (number) => {
    if (number === null || isNaN(number)) {
      return "0"; // Retourne une valeur par défaut si le nombre est null ou non valide
    }
    return number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters); // Basculer l'affichage des filtres
  };

  const rafraichirFournisseurs = () => {
    fetchData();
  };

  const filteredAvance = data.filter((command) => {
    const matchesSearch = command.fournisseur.nom_fournisseur
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesSearchId = command.id.toString().includes(searchTerm);
    const matchesFilterDate =
      filterDate === "" || command.date_avance === filterDate;

    return (matchesSearch || matchesSearchId) && matchesFilterDate;
  });

  return (
    <>
      <div className="container">
        <div className="header">
          <div className="cadre-option">
            <h1 className="titre-contenu">Liste des avances</h1>
            <div
              className={`option-view ${showFilters ? "view-open" : ""}`}
              onClick={toggleFilters}
            >
              <img src={showFilters ? moins : plus} alt="toggle filtre" />
            </div>
          </div>
          {showFilters && (
            <div className={`head-commande  ${showFilters ? "show" : ""}`}>
              <NouvAvance onAjout={rafraichirFournisseurs} />
              <input
                className="menu-filtre"
                type="date"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)} // Update filterDate state
              />
              <div className="menu-filtre-search">
                <input
                  type="text"
                  placeholder="Recherche..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm === "" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      backgroundColor: "#127ee3",
                      padding: "5px",
                      marginTop: "5px",
                    }}
                  >
                    <img
                      style={{ width: "19px" }}
                      src={search_ico}
                      alt="search icon"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="cadre-table-scroll">
          {loading ? (
            <Chargement />
          ) : (
            <table>
              <thead>
                <tr>
                  <th className="th-id text-center">Id</th>
                  <th className="th-date text-center">Date</th>
                  <th>Fournisseur</th>
                  <th className="text-end">Avance</th>
                  <th className="text-center">Mode de payement</th>
                </tr>
              </thead>
              <tbody>
                {filteredAvance.length > 0 ? (
                  filteredAvance.map((row) => (
                    <tr>
                      <th className="text-center">
                        {row.id < 10 ? `A0${row.id}` : `A${row.id}`}
                      </th>
                      <th className="text-center">
                        {row.date_avance ? row.date_avance : ""}
                      </th>
                      <th className="">{row.fournisseur.nom_fournisseur}</th>
                      <th className="text-end">
                        <span className="color-money">
                          {row.date_avance
                            ? formatNumber(row.montant_avance)
                            : ""}{" "}
                          AR{" "}
                        </span>
                      </th>
                      <th className="text-center">
                        {row.date_avance ? row.mode_payement : ""}
                      </th>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">Aucun resultat</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Avance;

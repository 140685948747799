import React, { useEffect, useState } from "react";
import TodayPrice from "./TodayPrice/TodayPrice";
import axios from "axios";
import NouvUser from "./User/NouvUser";

const Parametre = () => {
  const [data, setData] = useState([]);

  // GET price
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://faly-litchi.mg/api/show_price"
        );
        setData(response.data);
        console.log("Données :", response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchData();
  }, []);

  // Formatage montant
  const formatNumber = (number) => {
    if (number === null || isNaN(number)) {
      return "0"; // Valeur par défaut si le nombre est null ou non valide
    }
    return number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  // Vérifiez ici la longueur de `data`
  console.log(data.length); // Devrait afficher 0 initialement, puis le nombre d'éléments

  return (
    <div className="container">
      <div className="head-commande">
        <TodayPrice />
        <h1 className="titre-contenu">PARAMÈTRES</h1>
      </div>
      <br />
      <div className="cadre-table-scroll">
        <table>
          <thead>
            <tr>
              <th className="th-id text-center">Id</th>
              <th className="th-date text-center">Date</th>
              <th className="text-center">Avance (Ar)</th>
            </tr>
          </thead>
          <tbody>
            <tr key={data.id}>
              <td className="text-center">C00{data.id}</td>
              <td className="text-center">{data.date_today}</td>
              <td className="text-end">{formatNumber(data.prix)} AR</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div className="head-commande">
        <NouvUser />
        <h1 className="titre-contenu">USER</h1>
      </div>
    </div>
  );
};

export default Parametre;

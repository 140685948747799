import React, { useEffect, useState } from "react";
import "./stock.css";
import NouvLivraison from "./NouvLivraison";
import axios from "axios";
import PayeLivraison from "./PayeLivraison";
import Chargement from "../../Components/Chargement/Chargement";
import moins from "../../Assets/Icons/icons8-moins-24.png";
import plus from "../../Assets/Icons/icons8-plus-24.png";

const Stock = () => {
  // Pour Titre
  useEffect(() => {
    document.title = "Livraison";
  }, []);

  // Les données
  const [livraison, setLivraison] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(true);

  // Filtres
  const [filterStatusEtat, setFilterStatusEtat] = useState("Tous");
  const [filterFournisseur, setFilterFournisseur] = useState("Tous");

  // État pour gérer les éléments sélectionnés
  const [selectedIds, setSelectedIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const toggleSelect = (itemId) => {
    setSelectedIds((prev) =>
      prev.includes(itemId)
        ? prev.filter((i) => i !== itemId)
        : [...prev, itemId]
    );
  };

  const toggleSelectAll = () => {
    if (allSelected) {
      setSelectedIds([]);
    } else {
      setSelectedIds(filteredStock.map((row) => row.livraison_id));
    }
    setAllSelected(!allSelected);
  };

  const formatNumber = (number) => {
    return number
      ? number.toLocaleString(undefined, { maximumFractionDigits: 2 })
      : "0";
  };

  // GET livraison
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://faly-litchi.mg/api/show_Livraison"
      );
      setLivraison(response.data);
      console.log("données :", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleFilters = () => setShowFilters((prev) => !prev);

  useEffect(() => {
    fetchData();
  }, []);

  const rafraichirFournisseurs = () => {
    fetchData();
  };

  const filteredStock = livraison.filter((item) => {
    const matchesFilterStatus =
      filterStatusEtat === "Tous" || item.etat === filterStatusEtat;
    const matchesFilterFournisseur =
      filterFournisseur === "Tous" ||
      (filterFournisseur === "null" && item.sous_fournisseur === null) ||
      (filterFournisseur === "" && item.sous_fournisseur !== null);
    return matchesFilterStatus && matchesFilterFournisseur;
  });

  return (
    <>
      <div className="container">
        <div className="header">
          <div className="cadre-option">
            <h1 className="titre-contenu">Liste des livraisons</h1>
            <div
              className={`option-view ${showFilters ? "view-open" : ""}`}
              onClick={toggleFilters}
            >
              <img src={showFilters ? moins : plus} alt="toggle filtre" />
            </div>
          </div>
          {showFilters && (
            <div className={`head-commande ${showFilters ? "show" : ""}`}>
              <NouvLivraison onAjout={rafraichirFournisseurs} />
              <select
                className="menu-filtre"
                onChange={(e) => setFilterStatusEtat(e.target.value)}
                value={filterStatusEtat}
              >
                <option value="Tous">Tous état</option>
                <option value="payé">payé</option>
                <option value="non payé">non payé</option>
              </select>
              <select
                className="menu-filtre"
                onChange={(e) => setFilterFournisseur(e.target.value)}
                value={filterFournisseur}
              >
                <option value="Tous">Tous fournisseurs</option>
                <option value="null">Fournisseurs certifié</option>
                <option value="">Sous Fournisseurs</option>
              </select>
            </div>
          )}
        </div>
        <div className="cadre-table-scroll">
          {loading ? (
            <Chargement />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={allSelected}
                      onChange={toggleSelectAll}
                    />
                  </th>
                  <th className="th-id text-center">Id</th>
                  <th className="text-center">Date Livraison</th>
                  <th className="">Fournisseurs / Sous fournisseur</th>
                  <th className="text-end">Quantité livré</th>
                  <th className="text-center">Caissette</th>
                  <th className="text-center">N° commande</th>
                  <th className="text-center">Etat</th>
                </tr>
              </thead>
              <tbody>
                {filteredStock.length > 0 ? (
                  filteredStock.map((row) => (
                    <tr key={row.livraison_id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedIds.includes(row.livraison_id)}
                          onChange={() => toggleSelect(row.livraison_id)}
                        />
                      </td>
                      <td className="text-center">
                        {row.livraison_id < 10
                          ? `L0${row.livraison_id}`
                          : `L${row.livraison_id}`}
                      </td>
                      <td className="text-center">{row.date_livraison}</td>
                      <td>
                        {row.sous_fournisseur && row.sous_fournisseur !== "Null"
                          ? `${row.sous_fournisseur.nom_sous_fournisseur} (${row.nom_fournisseur})`
                          : row.nom_fournisseur}
                      </td>
                      <td className="text-end">
                        <strong>{formatNumber(row.quantite_livre)} kg</strong>
                      </td>

                      <td className="text-center">{row.nombre_caissette}</td>
                      <td className="th-contact text-center">
                        {row.commande_id < 10
                          ? `C0${row.commande_id}`
                          : `C${row.commande_id}`}
                      </td>

                      {row.etat === "payé" ? (
                        <td className="text-center">
                          <i>payé</i>
                        </td>
                      ) : (
                        <td>
                          <PayeLivraison
                            fournisseur_id={row.fournisseur_id}
                            nom_fournisseur={row.nom_fournisseur}
                            sous_fournisseur={
                              row.sous_fournisseur &&
                              row.sous_fournisseur !== "Null"
                                ? row.sous_fournisseur.nom_sous_fournisseur
                                : null
                            }
                            nombre_caissette={row.quantite_livre}
                            date_livraison={row.date_livraison}
                            livraison_id={row.livraison_id}
                            commande_id={row.commande_id}
                            sous_fournisseur_id={
                              row.sous_fournisseur &&
                              row.sous_fournisseur !== "Null"
                                ? row.sous_fournisseur.id
                                : null
                            }
                          />
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9">Aucun résultat</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Stock;

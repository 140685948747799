import React, { useEffect, useState } from "react";
import print_ico from "../../Assets/Icons/icons8-impression-50.png";
import axios from "axios";

const FactureAllCooperative = ({ selectedIds }) => {

  const [price, setPrice] = useState();
  const formatNumber = (number) => {
    return number
      ? number.toLocaleString(undefined, { maximumFractionDigits: 2 })
      : "0";
  };

  // GET price
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://faly-litchi.mg/api/show_price"
        );
        setPrice(response.data.prix);
        console.log("Données :", response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchData();
  }, []);

  const generatePDF = () => {
    console.log(selectedIds);
    // Group invoices in sets of four for each page
    const invoicesHtml = selectedIds
      .map(
        (entry) => `
      <div class="facture-container page-break"">
        <div class="header-facture">
          <div>
            <h3>COOPERATIVE TARATRA</h3>
            <p>FLO-ID 36 811</p>
            <p>Itendro - Fenerive Est</p>
            <p>STAT : 46201322009000194</p>
            <p>NIF : 5001509470</p>
            <p>CIF : 0188520</p>
            <p>Bénéficiaire : ${entry.cooperative.nom_cooperative} </p>
          </div>
          <div>
            <h4>Facture N° 0${entry.id}/FALY HORDEA/2024</h4>
            <p class="text-center">Copie</p>
            <p class="text-center">FALY HORDEA</p>
            <p class="text-center">FLOID : 28709</p>
            <br/>
            <p class="text-center">Ampodivoampeno - Tamatave</p>
          </div>
        </div>
        <p>Date : ${entry.date_distribution}</p>
        <table class="table-facture">
          <thead>
            <tr>
              <th>Quantité</th>
              <th class="text-end">Désignation (kg)</th>
              <th class="text-end">PU (Ar)</th>
              <th class="text-end">Montant (Ar)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${formatNumber((entry.quantite))} kg</td>
              <td>Litchis </br> Certifiés FAIRTRADE</td>
              <td>${formatNumber(price)}Ar</td>
              <td> ${formatNumber(price*entry.quantite)} Ar</td>
            </tr>
            <tr>
              <td class="text-center" colspan="3"><strong>Montant Total</strong></td>
              <td>${formatNumber(price*entry.quantite)} Ar</td>
            </tr>
          </tbody>
        </table>
        <div class="footer">Merci pour votre confiance.</div>
      </div>
    `
      )
      .join("");

    const fullHtml = `
      <html>
        <head>
          <title>Factures</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 10px; }
            .stat p { margin:5px }
            .facture-container { width: 100%; margin: 0 auto; }
            .header-facture { display: flex; justify-content: space-between; border-bottom: 1px dashed #ccc; padding-bottom: 10px; }
            .header-facture info {  width: 10px }
            .table-facture { width: 100%; border-collapse: collapse; margin-top: 40px; }
            .table-facture th, .table-facture td { border: 1px solid #ddd; padding: 8px; text-align: right; }
            .table-facture th { background-color: #646464; color: white; }
            .footer { margin-top: 40px; font-size: 12px; text-align: center; color: #777; }
            .page-break { page-break-after: always; }
                 </style>
        </head>
        <body onload="window.print(); window.close();">
          <div class="facture-grid">
            ${invoicesHtml}
          </div>
        </body>
      </html>
    `;

    const popupWin = window.open("", "_blank", "width=800,height=600");
    popupWin.document.write(fullHtml);
    popupWin.document.close();
  };

  return (
    <>
      <button className="btn btn-print" onClick={generatePDF}>
        <img src={print_ico} alt="print_icon" />
        <label>Imprimer tous</label>
      </button>
    </>
  );
};

export default FactureAllCooperative;

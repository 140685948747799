import React, { useEffect, useState } from "react";
import SelectSearch from "../../Components/SelectSearch/SelectSearch";
import axios from "axios";

const NouvAvance = ({ onAjout }) => {
  // State variables
  const [avance, setAvance] = useState("");
  const [dateAvance, setDateAvance] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [modePaiement, setModePaiement] = useState("espèce");
  const [fournisseurId, setFournisseurId] = useState("");
  const [fournisseurs, setFournisseurs] = useState([]);
  const [openWin, setOpenWin] = useState(false);
  const [notif, setNotif] = useState("");
  const [openNotif, setOpenNotif] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Function to submit avance
  const handleSubmit = async () => {
    if (avance === "") {
      notifyError("veuillez completer l'avance.");
      return;
    }
    const formData = {
      date_avance: dateAvance,
      fournisseur_id: fournisseurId,
      montant_avance: parseFloat(avance.replace(/\s/g, "")),
      mode_payement: modePaiement,
    };

    // Validation
    if (formData.montant_avance <= 0 || !fournisseurId) {
      notifyError("Veuillez remplir tous les champs requis.");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post(
        "https://faly-litchi.mg/api/create_avance",
        formData
      );
      notifySuccess();
      console.log(response.data);

      // const message = response.data.message;
      // if (message) {
        setOpenWin(false);
        setIsSubmitting(false);
        Initialise();
        onAjout();
      // } else {
      //   notifyError("Cette personne a déjà une avance en cours.");
      // }
    } catch (error) {
      notifyError("Erreur lors de l'enregistrement de l'avance.");
      console.error(
        "Erreur lors de la soumission des données :",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsSubmitting(false);
    }
  };


  // Open popup
  const Alerter = () => setOpenWin(true);

  // Close popup and reset values
  const Annuler = () => {
    setOpenWin(false);
    Initialise();
  };

  // Initialize values
  const Initialise = () => {
    setDateAvance(new Date().toISOString().slice(0, 10));
    setAvance("");
    setFournisseurId("");
    setModePaiement("espèce");
  };

  // Show success notification
  const notifySuccess = () => {
    setNotif("Opération réussie !");
    setIsError(false);
    setOpenNotif(true);
    setTimeout(() => setOpenNotif(false), 5000);
    Initialise();
  };

  // Show error notification
  const notifyError = (message) => {
    setNotif(message);
    setIsError(true);
    setOpenNotif(true);
    setTimeout(() => setOpenNotif(false), 5000);
  };

  // GET ALL FOURNISSEURS
  useEffect(() => {
    const fetchFournisseurs = async () => {
      try {
        const response = await axios.get(
          "https://faly-litchi.mg/api/show_fournisseur"
        );
        const options = response.data.map((fournisseur) => ({
          value: fournisseur.id,
          label: fournisseur.nom_fournisseur,
        }));
        setFournisseurs(options);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des fournisseurs :",
          error
        );
      }
    };

    fetchFournisseurs();
  }, []);

  // Format amount
  const formatNumber = (value) => {
    const numericValue = value.replace(/[^\d]/g, "");
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const handleChangeAvance = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setAvance(formattedValue);
  };

  // Handle supplier selection
  const handleSelectChange = (option) => {
    setFournisseurId(option.value);
  };

  return (
    <>
      <button className="btn-commande menu-filtre" onClick={Alerter}>
        <div>Nouvelle Avance</div>
      </button>

      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">NOUVELLE AVANCE</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form className="form-popup">
              <label>Fournisseur :</label>
              <SelectSearch
                options={fournisseurs}
                onChange={handleSelectChange}
              />

              <label>Date :</label>
              <input
                type="date"
                value={dateAvance}
                onChange={(e) => setDateAvance(e.target.value)}
              />

              <label>Avance (en Ar) :</label>
              <input
                type="text"
                placeholder="en Ar"
                value={avance}
                onChange={handleChangeAvance}
              />

              <label>Mode de paiement :</label>
              <select
                value={modePaiement}
                onChange={(e) => setModePaiement(e.target.value)}
              >
                <option value="Espèces">Espèces</option>
                <option value="Chèques">Chèques</option>
                <option value="Mvola">Mvola</option>
                <option value="Virement">Virement</option>
                <option value="AirtelMoney">AirtelMoney</option>
                <option value="Espace payeur">Espace payeur</option>
              </select>
            </form>
            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Annuler
              </button>
              <button
                className="btn btn-confirmer"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Envoi..." : "Enregistrer"}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className={`container-notif ${openNotif ? "show" : "hide"}`}>
        {openNotif && (
          <div className={isError ? "error" : "success"}>{notif}</div>
        )}
      </div>
    </>
  );
};

export default NouvAvance;

import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import "./dashboard.css";
import axios from "axios";

// Enregistrer les composants de Chart.js
Chart.register(...registerables);

const Dashboard = () => {
  const [data, setData] = useState({});
  const [chartData, setChartData] = useState(null);
  const [annualData, setAnnualData] = useState(null);

  // Fetch compte data
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://faly-litchi.mg/api/show_compte"
      );
      setData(response.data);
      console.log("Données :", response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    }
  };

  useEffect(() => {
    fetchData(); // Load initial data
  }, []);

  // Construct statistics data from the fetched data
  const dataStat = {
    commande: data.total_quantite_commande || 0,
    productions: data.nombre_livraison || 0,
    dechets: data.nombre_dechet || 0,
    sortiesPalette: data.nombre_palette || 0,
  };

  // Fonction pour formater le montant avec des séparateurs de milliers
  const formatMontant = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  useEffect(() => {
    // Update chart data when the API data is available
    if (data) {
      const chartData = {
        labels: [
          "P.Commandé (kg)",
          "P.Livré (kg)",
          "Déchets (kg)"
        ],
        datasets: [
          {
            label: "Statistiques",
            data: [
              dataStat.commande,
              dataStat.productions,
              dataStat.dechets,
            ],
            backgroundColor: [
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };

      setChartData(chartData);

      const annualStats = {
        labels: ["2023", "2024", "2025"],
        datasets: [
          {
            label: "Produits Sortie par Année",
            data: [0, 0, 0], // Replace with your actual data
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
        ],
      };

      setAnnualData(annualStats); // Set annual data state
    }
  }, [data, dataStat.commande,
    dataStat.productions,
    dataStat.dechets]); // Add data dependency to trigger when data changes

  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  return (
    <>
      <br />
      <div className="container-statistique">
        <h4>STATISTIQUES</h4>
        <div className="card-container">
          <div className="card">
            <h4>Fournisseurs</h4>
            <p>
              <i>{data.nombre_fournissseur || 0}</i>
            </p>
          </div>
          <div className="card">
            <h4>S.Fournisseurs</h4>
            <p>
              <i>{data.nombre_sous_fournisseur || 0}</i>
            </p>
          </div>
          <div className="card">
            <h4>P. Commandées</h4>
            <p>
              <i>{formatMontant(parseInt(data.total_quantite_commande)) || 0} kg </i>
            </p>
          </div>
          <div className="card">
            <h4>P. Livrés (kg)</h4>
            <p>
              <i>{formatMontant(dataStat.productions)} kg </i>
            </p>
          </div>
          <div className="card">
            <h4>Déchets (kg) </h4>
            <p>
              <i>{dataStat.dechets} kg </i>
            </p>
          </div>
          <div className="card">
            <h4>Sorties Palette</h4>
            <p>
              <i>{dataStat.sortiesPalette}</i>
            </p>
          </div>
        </div>
        {chartData ? (
          <Bar
            data={chartData}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        ) : (
          <p>Chargement des données...</p>
        )}
      </div>
      <div className="container-statistique">
        <h4>PRODUITS SORTIE PAR ANNEE</h4>
        {annualData ? (
          <Bar
            data={annualData}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        ) : (
          <p>Chargement des données annuelles...</p>
        )}
      </div>
    </>
  );
};

export default Dashboard;

import React, { useState } from "react";
import edit_ico from "../../Assets/Icons/icons8-modifier-50.png";

const EditCommande = ({
  id_commande,
  date_commande,
  fournisseur,
  quantite,
  etat,
}) => {
  const [openWin, setOpenWin] = useState(false);

  const [date_commandeUpdate, setDate_commandeUpdate] = useState(date_commande);
  const [fournisseurUpdate, setFournisseurUpdate] = useState(fournisseur);
  const [quantiteUpdate, setQuantiteUpdate] = useState(quantite);
  const etatUpdate = etat;

  // Convert the received date_commande to a format suitable for the input
  const formattedDate = new Date(date_commande).toISOString().slice(0, 10); // YYYY-MM-DD format

  // mise à jour des donnees dans base
  const handleSubmit = async () => {
    const formData = {
      commandeNo: id_commande,
      date_commande: date_commandeUpdate,
      fournisseur: fournisseurUpdate,
      quantite: quantiteUpdate,
      etat: etatUpdate,
    };
    try {
      localStorage.setItem("commandeData", JSON.stringify(formData));
      console.log("Data saved to local storage:", formData);
      // Perform any other actions after successful storage
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const Alerter = async () => {
    setOpenWin(true);
  };

  const Annuler = async () => {
    setOpenWin(false);
  };
  return (
    <>
      <button className="btn btn-edit" onClick={Alerter}>
        <img src={edit_ico} alt="edit_ico" />
      </button>
      {openWin ? (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">Modification</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form action="">
              <label htmlFor="">Commande N° :</label>
              <br />
              <input
                type="text"
                name="commandeNo"
                id=""
                disabled
                value={id_commande}
              />
              <br />
              <label htmlFor="">Date :</label>
              <br />
              <input
                type="date"
                name="dateCommande"
                id=""
                defaultValue={formattedDate}
                onChange={(e) => setDate_commandeUpdate(e.target.value)}
                disabled
              />
              <br />
              <label htmlFor="">Fournisseur :</label>
              <br />
              <input
                type="text"
                name="fournisseur"
                id=""
                value={fournisseurUpdate}
                onChange={(e) => setFournisseurUpdate(e.target.value)}
                disabled
              />
              <br />
              <label htmlFor="">Quantité (En T):</label>
              <br />
              <input
                type="text"
                name="quantite"
                id=""
                value={quantiteUpdate}
                onChange={(e) => setQuantiteUpdate(e.target.value)}
                disabled
              />
              <br />
            </form>
            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Annuler
              </button>
              <button className="btn btn-confirmer" onClick={handleSubmit}>
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default EditCommande;

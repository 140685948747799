import React, { useState } from "react";
import new_ico from "../../../Assets/Icons/icons8-plus-50.png";
import axios from "axios"; // Assurez-vous d'importer axios si vous l'utilisez pour les requêtes

const NouvUser = () => {
  // State variables
  const [openWin, setOpenWin] = useState(false);
  const [notif, setNotif] = useState("");
  const [openNotif, setOpenNotif] = useState(false);
  const [isError, setIsError] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Function to submit user data
  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      notifyError("Les mots de passe ne correspondent pas.");
      return;
    }

    const formData = {
      user :username,
      email,
      pass: password,
    };
    console.log(formData)

    try {
      const response = await axios.post(
        "https://faly-litchi.mg/api/create_user",
        formData
      );
      notifySuccess();
      console.log("Utilisateur créé :", response.data);
      // Optionnel : réinitialiser les champs après soumission
      setUsername("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setOpenWin(false);
    } catch (error) {
      notifyError("Erreur lors de la création de l'utilisateur.");
      console.error("Erreur lors de la soumission des données :", error);
    }
  };

  // Open popup
  const Alerter = () => {
    setOpenWin(true);
  };

  // Close popup and reset values
  const Annuler = () => {
    setOpenWin(false);
    setUsername("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  // Show success notification
  const notifySuccess = () => {
    setNotif("Opération réussie !");
    setIsError(false);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  // Show error notification
  const notifyError = (message) => {
    setNotif(message);
    setIsError(true);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  return (
    <>
      <button className="btn-commande menu-filtre" onClick={Alerter}>
        <img src={new_ico} alt="new_ico" />
        <div>Ajouter User</div>
      </button>

      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">NOUVEAU USER</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form className="form-popup">
              <label htmlFor="username">Nom d'utilisateur :</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label htmlFor="email">Email :</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="password">Mot de passe :</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor="confirmPassword">
                Confirmation du mot de passe :
              </label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </form>
            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Annuler
              </button>
              <button className="btn btn-confirmer" onClick={handleSubmit}>
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      )}

      <div className={`container-notif ${openNotif ? "show" : "hide"}`}>
        {openNotif && (
          <div className={isError ? "error" : "success"}>{notif}</div>
        )}
      </div>
    </>
  );
};

export default NouvUser;

import axios from 'axios';
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [username, setUsername] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const login = async (user, pass) => {
    if (user !== '' && pass !== '') {
      try {
        setIsLoading(true);
        const url = 'https://faly-litchi.mg/api/login_user';
        const response = await axios.post(url, {
          user,
          pass,
        });
        const responseJson = response.data;

        if (responseJson.result === 'ok') {
         
          localStorage.setItem('isLoggedIn', true);
          localStorage.setItem('username', user);

          setTimeout(() => {
            setUsername(user);
            navigate('/dashboard');
            setIsLoading(false);
          }, 5000);
        } else {
          console.log('Connexion non réussie !');
          setIsLoading(false);
          setError(responseJson.result);
          setUsername(null);
        }
      } catch (err) {
        setError('Une erreur est survenue. Veuillez réessayer plus tard.');
        setIsLoading(false);
        console.error('Erreur :', err);
        setUsername(null);
      }
    } else {
      setError('Tous les champs sont obligatoires !');
      setIsLoading(false);
      setUsername(null);
    }
  };

  const logout = () => {
    localStorage.setItem('isLoggedIn', false);
    localStorage.setItem('username', null);
    setIsLoading(false);
    setUsername(null);
    navigate('/');
  };

  useEffect(() => {
    const openAll = localStorage.getItem('isLoggedIn');
    if (openAll) {
      setIsLoggedIn(true);
    } else {
      localStorage.removeItem('isLoggedIn');
    }
  }, []);

  const value = {
    isLoggedIn,
    username,
    isLoading,
    error,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      "useAuth doit être utilisé à l'intérieur d'un AuthProvider"
    );
  }
  return context;
};

export default AuthContext;

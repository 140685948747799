import React, { useState } from "react";
import axios from "axios";

const NouvFournisseur = ({ onAjout }) => {
  const [openWin, setOpenWin] = useState(false);
  const [nom, setNom] = useState("");
  const [stat, setStat] = useState("");
  const [nif, setNif] = useState("");
  const [cif, setCif] = useState("");
  const [type, setType] = useState("");
  const [adresse, setAdresse] = useState("");
  const [contact, setContact] = useState("");

  const [notif, setNotif] = useState("");
  const [openNotif, setOpenNotif] = useState(false);
  const [isError, setIsError] = useState(false);

  
  const typesFournissseur = ["Global Gab", "Sous Fournisseurs", "COOPERATIVE TARATRA"];

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault(); // Empêche le rechargement de la page
    if (!nom || !adresse || !contact) {
      notifyError("Tous les champs doivent être remplis.");
      setIsSubmitting(false);
      return;
    }

    const formData = {
      nom_fournisseur: nom,
      adresse: adresse,
      stat: stat,
      type: type,
      nif: nif,
      cif: cif,
      contact: contact,
    };

    console.log(formData);

    // Dans le composant NouvFournisseur, après un ajout réussi
    try {
      const response = await axios.post(
        "https://faly-litchi.mg/api/create_fournisseur",
        formData
      );
      notifySuccess();
      console.log("Ajout réussi :", response.data);
      setOpenWin(false);
      setIsSubmitting(false);
      initialise();
      onAjout(); // Rafraîchit les données dans Fournisseur
    } catch (error) {
      notifyError("Erreur lors de l'enregistrement du fournisseur.", error);
    }
  };

  const Alerter = () => {
    setOpenWin(true);
  };

  const Annuler = () => {
    setOpenWin(false);
    initialise();
  };

  const notifySuccess = () => {
    setOpenWin(!openWin);
    setNotif("Opération réussie !");
    setIsError(false);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  const notifyError = (message) => {
    setNotif(message);
    setIsError(true);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  const initialise = () => {
    setNom("");
    setAdresse("");
    setContact("");
    setCif("");
    setNif("");
    setType("");
    setStat("");
    setIsSubmitting(false);
  };

  return (
    <>
      <button className="btn-commande menu-filtre" onClick={Alerter}>
        <div> Nouvelle Fournisseur</div>
      </button>
      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">NOUVEAU FOURNISSEUR</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form onSubmit={handleSubmit} className="form-popup">
              <label htmlFor="nom">Nom :</label>
              <br />
              <input
                type="text"
                name="nom"
                id="nom"
                placeholder="Nom du fournisseur..."
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
              <br />
              <label htmlFor="adresse">Adresse :</label>
              <br />
              <input
                type="text"
                name="adresse"
                id="adresse"
                placeholder="Adresse..."
                value={adresse}
                onChange={(e) => setAdresse(e.target.value)}
              />
               <label>Type Fournisseur:</label>
                <select
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  required
                >
                  <option value="">Sélectionner un type</option>
                  {typesFournissseur.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              <label htmlFor="STAT">STAT :</label>
              <br />
              <input
                type="text"
                name="STAT"
                id="STAT"
                placeholder="STAT..."
                value={stat}
                onChange={(e) => setStat(e.target.value)}
              />
              <label htmlFor="CIF">CIF :</label>
              <br />
              <input
                type="text"
                name="CIF"
                id="CIF"
                placeholder="CIF..."
                value={cif}
                onChange={(e) => setCif(e.target.value)}
              />
              <label htmlFor="NIF">NIF :</label>
              <br />
              <input
                type="text"
                name="NIF"
                id="NIF"
                placeholder="NIF..."
                value={nif}
                onChange={(e) => setNif(e.target.value)}
              />
              <br />
              <label htmlFor="contact">Contact :</label>
              <br />
              <input
                type="number"
                name="contact"
                id="contact"
                maxLength={10}
                placeholder="Contact..."
                value={contact}
                onChange={(e) => setContact(e.target.value.slice(0, 10))}
              />
              <br />
              <div className="alert-action">
                <button
                  className="btn btn-annuler"
                  type="button"
                  onClick={Annuler}
                >
                  Annuler
                </button>
                <button
                  className="btn btn-confirmer"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Envoi..." : "Enregistrer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Notification */}
      <div className={`container-notif ${openNotif ? "show" : ""}`}>
        {openNotif && (
          <div className={isError ? "error" : "success"}>{notif}</div>
        )}
      </div>
    </>
  );
};

export default NouvFournisseur;

import React, { useEffect, useState } from "react";
import "./palette.css";
import axios from "axios";
import moins from "../../Assets/Icons/icons8-moins-24.png";
import plus from "../../Assets/Icons/icons8-plus-24.png";
import Chargement from "../../Components/Chargement/Chargement";
import * as XLSX from "xlsx";

const Palette = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showFilters, setShowFilters] = useState(true);

  useEffect(() => {
    document.title = "Rapport";
  }, []);

  const fetchData = async () => {
    setLoading(true); // Démarrer le chargement
    try {
      const response = await axios.get("https://faly-litchi.mg/api/show_suivi");
      setData(Array.isArray(response.data) ? response.data : [response.data]);
      console.log("Données :", response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoading(false); // Fin du chargement
    }
  };

  useEffect(() => {
    fetchData(); // Charger les données initialement
  }, []);

  const formatNumber = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  const exportToExcel = () => {
    if (data.length === 0) {
      alert("Aucune donnée à exporter");
      return;
    }
  
    // Formater les données avec les unités
    const formattedData = data.map(item => ({
      "Quantité Commandé": `${formatNumber(item.quantite_commande)} kg`,
      "Nombre de caissette Obtenue": formatNumber(parseInt(item.nombre_caissette)),
      "Total Kg": `${formatNumber(item.total_kilo)} kg`,
      "Estimation kg": `${formatNumber(item.estimation_kilo)} kg`,
      "Palettes (2 kg)": item.nombre_palette2,
      "Palettes (5.5 kg)": item.nombre_palette55,
      "Total palettes": item.nombre_palettre,
      "Quantité déchets": `${item.quantite_dechet} %`
    }));
  
    // Créer une feuille à partir des données formatées
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Rapport");
  
    // Télécharger le fichier
    XLSX.writeFile(workbook, "rapport.xlsx");
  };
  

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <>
      <div className="container">
        <div className="header">
          <div className="cadre-option">
            <h1 className="titre-contenu">Rapport</h1>
            <div
              className={`option-view ${showFilters ? "view-open" : ""}`}
              onClick={toggleFilters}
            >
              <img src={showFilters ? moins : plus} alt="toggle filtre" />
            </div>
          </div>
          {showFilters && (
            <div className={`head-commande  ${showFilters ? "show" : ""}`}>
              <input
                type="button"
                className="menu-filtre excel"
                value="Export Excel"
                onClick={exportToExcel}
              />
            </div>
          )}
        </div>
        <div className="cadre-table-scroll">
          {loading ? (
            <Chargement />
          ) : (
            <table>
              <thead>
                <tr>
                  <th className="text-end">Quantité Commandé (kg)</th>
                 
                  <th className="text-end">Total Reçu Kg</th>
                  <th className="text-end">Estimation kg</th>
                  <th className="text-end">Nombre de caissette Obtenue</th>
                  <th className="th-date text-end">Palettes (2 kg)</th>
                  <th className="text-end">Palettes (5.5 kg)</th>
                  <th className="text-end">Total palettes</th>
                  <th className="text-end">Quantité déchets</th>
                  <th className="text-end">Total Avance (Ar) </th>
                  <th className="text-end">Total Avance restant (Ar) </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td className="text-end">
                      {formatNumber(item.quantite_commande)} kg
                    </td>
            
                    <td className="text-end">
                      {formatNumber(item.total_kilo)} kg
                    </td>
                    <td className="text-end">
                      {formatNumber(item.estimation_kilo)} kg
                    </td>
                    <td className="text-end">
                      {formatNumber(parseInt(item.nombre_caissette))}
                    </td>
                    <td className="text-end">{item.nombre_palette2}</td>
                    <td className="text-end">{item.nombre_palette55}</td>
                    <td className="text-end">{item.nombre_palettre}</td>
                    <td className="text-end">{item.quantite_dechet} %</td>
                    <td className="text-end"></td>
                    <td className="text-end"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Palette;

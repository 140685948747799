import React, { useState } from "react";
import new_ico from "../../../Assets/Icons/icons8-plus-50.png";
import axios from "axios";

const TodayPrice = () => {
  // State variables
  const [openWin, setOpenWin] = useState(false);
  const [notif, setNotif] = useState("");
  const [openNotif, setOpenNotif] = useState(false);
  const [isError, setIsError] = useState(false);
  const [prix, setPrix] = useState(0);
  const [dateToday, setDateToday] = useState(
    new Date().toISOString().slice(0, 10)
  );

  // Function to submit avance
  const handleSubmit = async () => {
    if (prix===0) {
      notifyError("prix vide");
    } else {
      const formData = {
        prix: prix,
        date_today: dateToday,
      };
      console.log(formData);
      try {
        const response = await axios.post(
          "https://faly-litchi.mg/api/create_price",
          formData
        );
        notifySuccess();
        console.log("Ajout réussi :", response.data);
      } catch (error) {
        notifyError("Erreur lors de l'enregistrement de la commande.");
        console.error(
          "Erreur lors de la soumission des données :",
          error.response ? error.response.data : error.message
        );
      }
      setOpenWin(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  // Open popup
  const Alerter = () => {
    setOpenWin(true);
  };

  // Close popup and reset values
  const Annuler = () => {
    setOpenWin(false);
  };

  // Show success notification
  const notifySuccess = () => {
    setNotif("Opération réussie !");
    setIsError(false);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  // Show error notification
  const notifyError = (message) => {
    setNotif(message);
    setIsError(true);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  return (
    <>
      <button className="btn-commande menu-filtre" onClick={Alerter}>
        <img src={new_ico} alt="new_ico" />
        <div>Nouvelle Prix</div>
      </button>

      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">PRIX AUJOURD'HUI</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form className="form-popup">
              <label htmlFor="">Date :</label>
              <input
                type="date"
                value={dateToday}
                onChange={(e) => setDateToday(e.target.value)}
              />
              <label htmlFor="">Prix par kilo :</label>
              <input
                type="number"
                value={prix}
                onChange={(e) => setPrix(e.target.value)}
              />
            </form>
            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Annuler
              </button>
              <button className="btn btn-confirmer" onClick={handleSubmit}>
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      )}

      <div className={`container-notif ${openNotif ? "show" : "hide"}`}>
        {openNotif && (
          <div className={isError ? "error" : "success"}>{notif}</div>
        )}
      </div>
    </>
  );
};

export default TodayPrice;

import React, { useEffect, useState } from "react";
import "./dechet.css";
import axios from "axios";
import Chargement from "../../Components/Chargement/Chargement";
import moins from "../../Assets/Icons/icons8-moins-24.png";
import plus from "../../Assets/Icons/icons8-plus-24.png";


const Dechet = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(true); 

  useEffect(() => {
    document.title = "dechets";
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://faly-litchi.mg/api/show_dechet_all"
      );
      setData(response.data);
      console.log("Données :", response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoading(false);
    }
  };

  const formatNumber = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  useEffect(() => {
    fetchData(); // Charger les données initialement
  }, []);

  const toggleFilters = () => {
    setShowFilters(!showFilters); // Basculer l'affichage des filtres
  };

  return (
    <>
      <div className="container">
      <div className="header">
        <div className="cadre-option">
          <h1 className="titre-contenu">Suivi des déchets</h1>
          <div
            className={`option-view ${showFilters ? "view-open" : ""}`}
            onClick={toggleFilters}
          >
            <img src={showFilters ? moins : plus} alt="toggle filtre" />
          </div>
        </div>
        {showFilters && (
        <div className={`head-commande  ${showFilters ? "show" : ""}`}>
      
        </div>
        )}
        </div>
        <div className="cadre-table-scroll">
          {loading ? (
            <Chargement />
          ) : (
            <table>
              <thead>
                <tr>
                  <th className="">Fournisseurs</th>
                  <th className="text-end">Quantité livrée</th>
                  <th className="text-end">Pourcentage déchet (Ar)</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((row) => (
                    <tr>
                      <td className="">
                        {row.nom_fournisseur}
                      </td>
                      <td className="text-end">{formatNumber(row.quantite_livraison)} kg</td>
                      <td className="text-end">
                        {row.pourcentage_dechet} %
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="text-center">
                      Aucun résultat
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Dechet;

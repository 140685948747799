import axios from "axios";
import React, { useEffect, useState } from "react";
import SelectSearch from "../../Components/SelectSearch/SelectSearch";

const Distribution = (onAjout) => {
  const [totalDistribution, setTotalDistribution] = useState(0);
  const [cooperatives, setCooperatives] = useState([
    { fournisseur: null, cartons: "" },
  ]);
  const [dateCommande, setDateCommande] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [openWin, setOpenWin] = useState(false);
  const [quantiteLivre, setQuantiteLivre] = useState(0);
  const [optionsFournisseurs, setOptionsFournisseurs] = useState([]);
  const [notif, setNotif] = useState("");
  const [openNotif, setOpenNotif] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  

  useEffect(() => {
    const fetchFournisseurs = async () => {
      try {
        const response = await axios.get(
          "https://faly-litchi.mg/api/show_cooperative"
        );
        const options = response.data.map((cooperative) => ({
          value: cooperative.id,
          label: cooperative.nom_cooperative,
        }));
        setOptionsFournisseurs(options);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des coopératives :",
          error
        );
      }
    };

    fetchFournisseurs();
  }, []);

  useEffect(() => {
    if (dateCommande) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://faly-litchi.mg/api/show_livraison_date/${dateCommande}`
          );
          setQuantiteLivre(response.data.somme); // Affiche l'avance dans l'input
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des détails de la livraison :",
            error
          );
        }
      };
      fetchData();
    }
  }, [dateCommande]);

  const handleAddFournisseur = () => {
    setCooperatives([...cooperatives, { fournisseur: null, cartons: "" }]);
  };

  const handleFournisseurChange = (index, selectedOption) => {
    const newCooperatives = [...cooperatives];
    newCooperatives[index].fournisseur = selectedOption;
    setCooperatives(newCooperatives);
  };

  const handleCartonsChange = (index, value) => {
    const newCooperatives = [...cooperatives];
    newCooperatives[index].cartons = value;
    setCooperatives(newCooperatives);
  };

  const handleRemoveFournisseur = (index) => {
    const newCooperatives = cooperatives.filter((_, i) => i !== index);
    setCooperatives(newCooperatives);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!totalDistribution) {
      notifyError("La quantité totale doit être renseignée.");
      return;
    }

    if(totalDistribution>quantiteLivre){
      notifyError("La livraison est inssuffisant pour cette distribution");
      return;
    }

    const hasEmptyCartons = cooperatives.some(
      (f) => !f.cartons || f.cartons.trim() === ""
    );
    if (hasEmptyCartons) {
      notifyError("Au moins un nombre de cartons est vide.");
      return;
    }

    const hasEmptyFournisseur = cooperatives.some((f) => !f.fournisseur);
    if (hasEmptyFournisseur) {
      notifyError("Veuillez sélectionner un fournisseur pour chaque ligne.");
      return;
    }

    
    if (totalDistributionSaisis < totalDistribution || totalDistributionSaisis > totalDistribution) {
      notifyError("le quantité à distrubié doit être rempli");
      return;
    }


    const formData = {
      date_distribution: dateCommande,
      quantite: totalDistribution,
      livraison_cooperative: cooperatives
        .map((f) => ({
          cooperative_id: f.fournisseur?.value,
          quantite: f.cartons,
        }))
        .filter((f) => f.cooperative_id),
    };

    console.log(formData);
    setIsSubmitting(true);
    // Uncomment below to send data to API
    try {
      const response = await axios.post(
        "https://faly-litchi.mg/api/create_livraison_cooperative",
        formData
      );
       notifySuccess();
      console.log("Ajout réussi :", response.data);
      setOpenWin(false);
      setIsSubmitting(false);
      // onAjout();
    } catch (error) {
      notifyError("Erreur lors de l'enregistrement de la commande.");
      console.error(
        "Erreur lors de la soumission des données :",
        error.response ? error.response.data : error.message
      );
    }
  };

  const Alerter = () => setOpenWin(true);
  const Annuler = () => {
    setIsSubmitting(false);
    setTotalDistribution(0);
    setOpenWin(false);
  };

  const totalDistributionSaisis = cooperatives.reduce(
    (acc, fournisseur) => acc + (parseInt(fournisseur.cartons) || 0),
    0
  );

  const getFilteredOptions = () => {
    const selectedValues = cooperatives
      .map((f) => f.fournisseur?.value)
      .filter(Boolean);
    return optionsFournisseurs.filter(
      (option) => !selectedValues.includes(option.value)
    );
  };

  const notifySuccess = (message) => {
    setNotif(message);
    setIsError(false);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  const notifyError = (message) => {
    setNotif(message);
    setIsError(true);
    setOpenNotif(true);
    setTimeout(() => {
      setOpenNotif(false);
    }, 5000);
  };

  const formatNumber = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  return (
    <>
      <button className="btn-commande menu-filtre" onClick={Alerter}>
        <div> Nouvelle distribution</div>
      </button>
      {openWin && (
        <div className="cadre-popup">
          <div className="popup">
            <div className="bar-popup">
              <div className="titre-popup">NOUVELLE DISTRIBUTION</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Date de livraison:</label>
                <input
                  type="date"
                  value={dateCommande}
                  onChange={(e) => setDateCommande(e.target.value)}
                />
                <label>Quantité livré :</label>
                <input
                  type="text"
                  value={`${formatNumber(quantiteLivre)} kg`}
                  onChange={(e) => setQuantiteLivre(e.target.value)}
                  disabled
                />
                <label>Total à distribuer :</label>
                <input
                  type="number"
                  value={totalDistribution}
                  onChange={(e) => setTotalDistribution(e.target.value)}
                />
              </div>
              <div>
                <label>Bénéficiaires :</label>
                <br />
                {cooperatives.map((fournisseur, index) => (
                  <div key={index} className="input-quantite-3">
                    <div className="frs">
                      <SelectSearch
                        name="fournisseur"
                        options={getFilteredOptions()}
                        value={fournisseur.fournisseur}
                        onChange={(selectedOption) =>
                          handleFournisseurChange(index, selectedOption)
                        }
                        placeholder="Sélectionner un fournisseur"
                        isClearable
                        
                      />
                    </div>
                    <div className="frs">
                      <input
                        type="number"
                        name="quantite"
                        value={fournisseur.cartons}
                        onChange={(e) =>
                          handleCartonsChange(index, e.target.value)
                        }
                        placeholder="Quantite"
                  
                      />
                    </div>
                    <div className="supp">
                      <input
                        type="button"
                        onClick={() => handleRemoveFournisseur(index)}
                        value={"x"}
                      />
                    </div>
                  </div>
                ))}
                {totalDistributionSaisis < totalDistribution && (
                  <button
                    className="btn btn-more"
                    type="button"
                    onClick={handleAddFournisseur}
                  >
                    +
                  </button>
                )}
              </div>
              {totalDistributionSaisis > totalDistribution && (
                <div className="error-message" style={{ color: "red" }}>
                  Ce n'est pas logique
                </div>
              )}
              <div className="alert-action">
                <button className="btn btn-annuler" onClick={Annuler}>
                  Annuler
                </button>
                <button className="btn btn-confirmer" type="submit">
                  {isSubmitting ? "Envoi..." : "Enregistrer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Notification */}
      <div className={`container-notif ${openNotif ? "show" : "hide"}`}>
        {openNotif && (
          <div className={isError ? "error" : "success"}>{notif}</div>
        )}
      </div>
    </>
  );
};

export default Distribution;

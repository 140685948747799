import React from "react";
import "./navbar.css";
import logo_user from "../../Assets/Icons/icons8-utilisateur-50.png";
import parametre_ico from "../../Assets/Icons/icons8-paramètres-50 (1).png";
import help_ico from "../../Assets/Icons/icons8-aide-50.png";
import Logout from "../Logout/Logout";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const username = localStorage.getItem("username");

  return (
    <>
      <nav>
        <div className="container-nav">
          <div className="logo">LITCHI PROCESSING</div>
          <div className="user">
            <button className="btn-ico">
              <img src={help_ico} alt="help icon" />
            </button>
            <NavLink to={"Parametre"}>
              <button className="btn-ico">
                <img src={parametre_ico} alt="settings icon" />
              </button>
            </NavLink>
            <div className="user-icon">
              <img className="btn-ico" src={logo_user} alt="user icon" />
              {username && <div className="tooltip">{username}</div>}
            </div>
            <Logout />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

import React, { useEffect, useState } from "react";
import Chargement from "../../Components/Chargement/Chargement";
import axios from "axios";
import moins from "../../Assets/Icons/icons8-moins-24.png";
import plus from "../../Assets/Icons/icons8-plus-24.png";
import NouvSoufrage from "./NouvSoufrage";
import DetailSouffrage from "./DetailSouffrage";

const Soufrage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(true);

  useEffect(() => {
    document.title = "Souffrage";
  }, []);

  // GET SOUFRAGE FOURNISSE
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://faly-litchi.mg/api/show_souffrage_fournisseur"
      );
      setData(response.data);
      // console.log("Données :", response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  const rafraichirFournisseurs = () => {
    fetchData();
  };
  return (
    <>
      <div className="container">
        <div className="header">
          <div className="cadre-option">
            <h1 className="titre-contenu">Liste des Souffrages</h1>
            <div
              className={`option-view ${showFilters ? "view-open" : ""}`}
              onClick={toggleFilters}
            >
              <img src={showFilters ? moins : plus} alt="toggle filtre" />
            </div>
          </div>
          {showFilters && (
            <div className={`head-commande ${showFilters ? "show" : ""}`}>
              <NouvSoufrage  onAjout={rafraichirFournisseurs}/>
            </div>
          )}
        </div>
        <div className="cadre-table-scroll">
          {loading ? (
            <Chargement />
          ) : (
            <table>
              <thead>
                <tr>
                  <th className="th-contact text-center">Numéro Lot</th>
                  <th className="th-date text-center">Date</th>
                  <th className="">Fournisseurs</th>
                  <th className="text-end">Nombre Caissette</th>
                  <th className="text-end">N° box</th>
                  <th className="text-center">Options</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((row) => (
                    <tr>
                      <td className="text-center">{row.numero_de_lot}</td>
                      <td className="text-ceneter">{row.date_souffrage}</td>
                      <td className="">{row.fournisseur.nom_fournisseur}</td>
                      <td className="text-end">{row.quantite}</td>
                      <td className="text-end">{row.numero_box}</td>
                      <td className="text-center">
                        <DetailSouffrage
                          numero_de_lot={row.numero_de_lot}
                          numSouffrage={row.souffrage_id}
                          quantite={row.quantite}
                          date_souffrage={row.date_souffrage}
                          nom_fournisseur={row.fournisseur.nom_fournisseur}
                          shift={row.shift}
                          numero_box={row.numero_box}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="text-center">
                      Aucun résultat
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Soufrage;

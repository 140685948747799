import React, { useEffect, useState } from "react";
import "./commande.css";
import DeleteCommande from "./DeleteCommande";
import EditCommande from "./EditCommande";
import NouvCommande from "./NouvCommande";
import PrintCommande from "./PrintCommande";
import axios from "axios";
import Chargement from "../../Components/Chargement/Chargement";
import moins from "../../Assets/Icons/icons8-moins-24.png";
import plus from "../../Assets/Icons/icons8-plus-24.png";
import search_ico from "../../Assets/Icons/icons8-chercher-50.png";

import * as XLSX from "xlsx";

const Commande = () => {
  useEffect(() => {
    document.title = "Commande";
  }, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("Tous");
  const [filterDate, setFilterDate] = useState(""); // New state for date filter
  const [showFilters, setShowFilters] = useState(true);

  // État pour gérer les éléments sélectionnés
  const [selectedIds, setSelectedIds] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://faly-litchi.mg/api/show_commande_all"
      );
      setData(response.data);
      // setData(commande);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoading(false);
    }
  };

  // État pour la sélection totale
  const [allSelected, setAllSelected] = useState(false);
  // Fonction pour sélectionner/désélectionner un élément
  const toggleSelect = (item) => {
    setSelectedIds((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };

  // Fonction pour gérer la sélection/désélection de tous les éléments
  const toggleSelectAll = () => {
    if (allSelected) {
      setSelectedIds([]);
    } else {
      // Select all filtered items instead of all data
      setSelectedIds(filteredCommands);
    }
    setAllSelected(!allSelected);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatNumber = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  const filteredCommands = data.filter((command) => {
    const matchesSearch = command.fournisseur.nom_fournisseur
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesSearchId = command.id.toString().includes(searchTerm);
    const matchesFilterStatus =
      filterStatus === "Tous" || command.etat === filterStatus;
    const matchesFilterDate =
      filterDate === "" || command.date_commande === filterDate;

    return (
      (matchesSearch || matchesSearchId) &&
      matchesFilterStatus &&
      matchesFilterDate
    );
  });

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredCommands.map((row) => ({
        Id: `${row.id < 10 ? `C0${row.id}` : `C${row.id}`}`,
        Date: row.date_commande,
        Fournisseur: row.fournisseur ? row.fournisseur.nom_fournisseur : "",
        SousFournisseur: row.nom_sous_fournisseur || "",
        QuantiteCommandee: row.quantite_commande,
        QuantiteLivree: row.quantite_livre,
        Avance: formatNumber(row.montant_avance),
        Etat: row.etat,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Commandes");
    XLSX.writeFile(workbook, "commandes.xlsx");
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const rafraichirFournisseurs = () => {
    fetchData();
  };

  return (
    <div className="container">
      <div className="header">
        <div className="cadre-option">
          <h1 className="titre-contenu">Liste des commandes</h1>
          <div
            className={`option-view ${showFilters ? "view-open" : ""}`}
            onClick={toggleFilters}
          >
            <img src={showFilters ? moins : plus} alt="toggle filtre" />
          </div>
        </div>

        {showFilters && (
          <div className={`head-commande  ${showFilters ? "show" : ""}`}>
            <NouvCommande
              onCreate={fetchData}
              onAjout={rafraichirFournisseurs}
            />
            <div className="menu-filtre-search">
              <input
                type="text"
                placeholder="Recherche..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {searchTerm === "" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    backgroundColor: "#127ee3",
                    padding: "5px",
                     marginTop:"5px"
                  }}
                >
                  <img
                    style={{ width: "19px" }}
                    src={search_ico}
                    alt="search icon"
                  />
                </div>
              )}
            </div>

            <input
              className="menu-filtre"
              type="date"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)} // Update filterDate state
            />
            <select
              className="menu-filtre"
              onChange={(e) => setFilterStatus(e.target.value)}
              value={filterStatus}
            >
              <option value="Tous">Tous</option>
              <option value="encours">En cours</option>
              <option value="livré">Livrée</option>
              <option value="Annulée">Annulée</option>
              <option value="envoyé">Envoyé</option>
            </select>
            <input
              type="button"
              className="menu-filtre excel"
              value="Export Excel"
              onClick={exportToExcel}
            />
          </div>
        )}
      </div>

      <div className="cadre-table-scroll">
        {loading ? (
          <Chargement />
        ) : (
          <table>
            <thead>
              <tr>
                <th className="th-id">
                  <input
                    type="checkbox"
                    checked={allSelected}
                    onChange={toggleSelectAll}
                  />
                </th>
                <th className="th-id text-center">Id</th>
                <th className="th-date text-center">Date</th>
                <th className="">Fournisseurs</th>
                <th className="th-date text-end">Quantité commandée</th>
                <th className="th-date text-end">Quantité livrée</th>
                <th className="th-date text-end">Avance restante (Ar)</th>
                <th className="text-center">État</th>
                <th className="th-id text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCommands.length > 0 ? (
                filteredCommands.map((row) => (
                  <tr key={row.id}>
                    <th>
                      <input
                        type="checkbox"
                        checked={selectedIds.includes(row)}
                        onChange={() => toggleSelect(row)}
                      />
                    </th>
                    <td className="text-center">
                      {row.id < 10 ? `C0${row.id}` : `C${row.id}`}
                    </td>
                    <td className="text-center">{row.date_commande}</td>
                    <td>
                      {row.fournisseur ? row.fournisseur.nom_fournisseur : ""}
                    </td>
                    <td className="text-end">{formatNumber(row.quantite_commande)} kg</td>
                    <td className="text-end">{row.quantite_livre} kg</td>
                    <td className="text-end">
                      <span className="color-money">
                        <i>{formatNumber(row.montant_avance)} AR </i>
                      </span>
                    </td>
                    <td className="text-center">
                      <i>{row.etat}</i>
                    </td>
                    <td>
                      <div className="option">
                        <DeleteCommande
                          id_commande={row.id}
                          date_commande={row.date_commande}
                          onDelete={fetchData}
                        />
                        <EditCommande
                          id_commande={row.id}
                          date_commande={row.date_commande}
                          fournisseur={row.fournisseur.nom_fournisseur}
                          quantite={row.quantite_commande}
                          etat={row.etat}
                          onEdit={fetchData}
                        />
                        <PrintCommande
                          id_commande={row.id}
                          date_commande={row.date_commande}
                          fournisseur={row.fournisseur_id}
                          quantite={row.quantite_commande}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">
                    Aucun résultat
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Commande;

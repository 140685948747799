import axios from "axios";
import React, { useEffect, useState } from "react";
import "./archives.css"
import print_ico from "../../Assets/Icons/icons8-impression-50.png";

const PrintFactureCooperative = ({id,nom_cooperative,date_distribution,quantite,}) => {
  const [price, setPrice] = useState(0); // Default value to avoid undefined
  const [openWin, setOpenWin] = useState(false);

  const formatNumber = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  const Alerter = () => {
    setOpenWin(true);
  };

  const Annuler = () => {
    setOpenWin(false);
  };

  // GET price
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://faly-litchi.mg/api/show_price"
        );
        setPrice(response.data.prix || 0); // Set default if data is null
        console.log("Données :", response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchData();
  }, []);

  const generatePDF = () => {
    const tableau = document.getElementById("divToPrint").innerHTML;
    const popupWin = window.open("", "_blank", "width=800,height=600");
    popupWin.document.open();
    popupWin.document.write(`
       <html>
        <head>
          <title>Factures</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 10px; }
            .stat p { margin:5px }
            .facture-container { width: 100%; margin: 0 auto; }
            .header-facture { display: flex; justify-content: space-between; border-bottom: 1px dashed #ccc; padding-bottom: 10px; }
            .header-facture info {  width: 10px }
            .table-facture { width: 100%; border-collapse: collapse; margin-top: 40px; }
            .table-facture th, .table-facture td { border: 1px solid #ddd; padding: 8px; text-align: right; }
            .table-facture th { background-color: #646464; color: white; }
            .footer { margin-top: 40px; font-size: 12px; text-align: center; color: #777; }
            .page-break { page-break-after: always; }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          <div class="facture-container">
            ${tableau}
          </div>
        </body>
      </html>
      `);
    popupWin.document.close();
  };

  return (
    <>
      <button className="btn btn-print" onClick={Alerter}>
        <img src={print_ico} alt="print_icon" />
        <label>Facturer</label>
      </button>

      {openWin && (
        <div className="cadre-popup">
          <div className="popup-facture">
            <div className="bar-popup">
              <div className="titre-popup">FACTURE</div>
              <button onClick={Annuler} className="btn btn-close">
                x
              </button>
            </div>
            <div id="divToPrint" className="facture-container">
              <div className="header-facture">
                <div>
                  <h3>COOPERATIVE TARATRA</h3>
                  <p>FLO-ID 36 811</p>
                  <p>Itendro - Fenerive Est</p>
                  <p>STAT : 46201322009000194</p>
                  <p>NIF : 5001509470</p>
                  <p>CIF : 0188520</p>
                  <p>Bénéficiaire : </p>
                </div>
                <div>
                  <h4>Facture N° {id}/FALY HORDEA/2024</h4>
                  <p className="text-center">Copie</p>
                  <p className="text-center">FALY HORDEA</p>
                  <p className="text-center">FLOID : 28709</p>
                  <br />
                  <p className="text-center">Ampodivoampeno - Tamatave</p>
                </div>
              </div>
              <div className="facture-details">
                <p>Date : {date_distribution}</p>
              </div>

              <table className="table-facture">
                <thead>
                  <tr>
                    <th>Quantité</th>
                    <th className="text-end">Désignation (kg)</th>
                    <th className="text-end">PU (Ar)</th>
                    <th className="text-end">Montant (Ar)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-end">{formatNumber(quantite)} kg</td>
                    <td>
                      Litchis <br /> Certifiés FAIRTRADE
                    </td>
                    <td className="text-end">{formatNumber(price)} Ar</td>
                    <td className="text-end">{formatNumber(price*quantite)} Ar</td>
                  </tr>
                  <tr>
                    <td className="text-center" colSpan="3">
                      <strong>Montant Total</strong>
                    </td>
                    <td className="text-end">{formatNumber(price*quantite)} Ar</td>
                  </tr>
                </tbody>
              </table>
              <div className="footer text-center">
                Merci pour votre confiance.
              </div>
            </div>
            <div className="alert-action">
              <button className="btn btn-annuler" onClick={Annuler}>
                Annuler
              </button>
              <button className="btn btn-confirmer" onClick={generatePDF}>
                imprimer
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrintFactureCooperative;

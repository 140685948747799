import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import "./login.css";

const Login = () => {
    // Pour Titre
    useEffect(() => {
      document.title = "Login";
    }, []);

  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [msg, setMsg] = useState("");

  const { login, error, isLoading } = useAuth();

  const LoginSubmit = async (e) => {
    e.preventDefault();

    try {
      await login(user, pass);
    } catch (err) {
      console.error("Login error:", err);
      setMsg("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
  };



  return (
    <>
      <div className="container-login">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="screen">
            <div className="screen__content">
              <form className="login" onSubmit={LoginSubmit}>
                <div className="container-logo">
                  <img className="logo_hita" alt="" />
                  <label
                    style={{
                      textAlign: "center",
                      color: "#b90a28",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    LITCHI PROCCESSING <br />
                    LOGIN
                  </label>
                </div>
                <div className="login__field">
                  <i className="login__icon fas fa-user"></i>
                  <input
                    type="text"
                    className="login__input"
                    placeholder="User name"
                    onChange={(e) => setUser(e.target.value)}
                  />
                </div>
                <div className="login__field">
                  <i className="login__icon fas fa-lock"></i>
                  <input
                    type="password"
                    className="login__input"
                    placeholder="Password"
                    onChange={(e) => setPass(e.target.value)}
                  />
                </div>
                <button
                  className={`button login__submit ${
                    isLoading ? "connect" : ""
                  }`}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span className="button__text">Connexion...</span>
                  ) : (
                    <span className="button__text">Se connecter</span>
                  )}
                </button>
                <p style={{ textAlign: "center" }}>
                  {error !== "" ? (
                    <span className="error">{error}</span>
                  ) : (
                    <span className="success">{msg}</span>
                  )}
                </p>
               
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
